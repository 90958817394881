/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Competitor {

  public id:                number;
  public name:              string;
  public region:            string;
  public date:              number;
  public base:              number;
  public price:             number;
  public discountCode:      string;
  public giftWithPurchase:  string;
  public availability:      number;
  public url:               string;

  constructor(parameters: {
			id?:                number,
			name?:              string,
			region?:            string,
			date?:              number,
			base?:              number,
			price?:             number,
			discountCode?:      string,
			giftWithPurchase?:  string,
			availability?:      number,
			url?:               string,
    } = {}) {
	  this.id               = parameters.id || null;
	  this.name             = parameters.name || 'N/A';
	  this.region           = parameters.region || '';
	  this.date             = parameters.date || 0;
	  this.base             = parameters.base || 0;
	  this.price            = parameters.price || 0;
	  this.discountCode     = parameters.discountCode || '';
	  this.giftWithPurchase = parameters.giftWithPurchase || '';
	  this.availability     = parameters.availability || 0;
	  this.url              = parameters.url || '';
	}
}

export class CompetitorFactory {
	static create(cmp_record: AjaxCompetitor) {

    return new Competitor({
    	id:               cmp_record.cmp_id,
			name:     		    cmp_record.cmp_name,
			region:           cmp_record.prh_region,
			date:             cmp_record.prh_date,
			base:     		    cmp_record.prh_base,
			price:     		    cmp_record.prh_price,
			discountCode:     cmp_record.prh_discount_code,
			giftWithPurchase: cmp_record.prh_gift_with_purchase,
			availability:     cmp_record.availability,
			url:              cmp_record.cmv_url,
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxCompetitor {
  cmp_id:                 number;
  cmp_name:               string;
  prh_region:             string;
  prh_date:               number;
  prh_base:               number;
  prh_price:              number;
  prh_discount_code:      string;
  prh_gift_with_purchase: string;
  availability:           number;
  cmv_url:                string;
}