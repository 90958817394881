/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Subject, Subscription, forkJoin } from 'rxjs';
import { switchMap, tap, takeUntil, first } from 'rxjs/operators';

/**
 * Import 3rd party modules
 */
import { ResizeEvent } from 'angular-resizable-element';
import { UIChart } from 'primeng/components/chart/chart';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from '../general-services/browser-window.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { AjaxData, AjaxDataService } from '../ajax-services/ajax-data.service';
import { DialogService } from '../general-services/dialog.service';
import { HistoryGraphComponent } from '../history-graph/history-graph.component';
import { RegionDescriptions, RegionCurrencies, competitorsColumns, reviewsColumns, priceChartOptions, stockChartOptions, adwordsChartOptions, textadsChartOptions, salesChartOptions } from "../data-definitions/global-constants"
import { Competitor, CompetitorFactory } from '../data-definitions/competitor';
import { Variation, VariationFactory } from '../data-definitions/variation';
import { Product, ProductFactory } from '../data-definitions/product';

/**
 * Define the component
 */
@Component({
  selector: 'app-variation',
  templateUrl: './variation.component.html',
  styleUrls: ['./variation.component.css']
})
export class VariationComponent implements OnInit {

/**
 * Public properties
 */
  public math = Math; // make math available in the template
  public region = "Mars";
  public regionDescriptions = RegionDescriptions;
  public regionCurrencies = RegionCurrencies;
  public variationId;
  public variation: Variation;
  public product: Product;
  public competitorRecords = [];
  public totalCompetitorRecords = 0;
  public priceData: any;
  public stockData: any;
  public adwordData: any;
  public salesData: any;
  public loading = true;
  public competitorsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 34, rows: 10, columns: competitorsColumns };
  public developmentPlacebo = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 34, rows: 10, columns: competitorsColumns };
  public priceOptions = priceChartOptions;
  public stockOptions = stockChartOptions;
  public adwordsOptions = adwordsChartOptions;
  public textadsOptions = textadsChartOptions;
  public salesOptions = salesChartOptions;
  public lazyLoadOnInit = false;
  public dataReset: boolean = true;
  public competitors = [];
  public recordsSelected = [];
  public chartReset = true;
  public stockInterval = 'Q';
  public salesInterval = 'Q';
  public adwordsInterval = 'M';
  public textadsInterval = 'M';
  public comparisonInterval = 'Q';

/**
 * Private properties
 */
  private windowRef: Window;
  private sortField = '';
  private sortOrder = 1;
  private onDestroy$ = new Subject();

/**
 * External linkage
 */
  @ViewChild('competitorsContainer', { static: true }) competitorsContainer: any;
  @ViewChild('competitorsTable', { static: false }) competitorsTable: any;
  @ViewChild('stockChart', { static: false }) stockChart: UIChart;
  @ViewChild('salesChart', { static: false }) salesChart: UIChart;

/**
 * The constructor and init/destroy methods
 */
  public constructor(private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private browserWindowService: BrowserWindowService,
                     private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
   @Inject(DOCUMENT) private documentRef: Document) {

  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url;
    this.variationId = this.activatedRoute.snapshot.paramMap.get('id');

    /** Set the default sort column/direction */
    const sortColumn = this.competitorsPanelMeta.columns.find(column => { return column.sortOrder === 1 || column.sortOrder === -1 });
    if (sortColumn) {
      this.sortField = sortColumn.field;
      this.sortOrder = sortColumn.sortOrder;
    }
    this.windowRef = this.browserWindowService.window;

    /** Add a callback function to render currency in charts based on region */
    this.salesOptions.scales.yAxes[1].ticks.callback = value => {
      return value.toLocaleString("en-GB",{style: "currency", currency: this.regionCurrencies[this.region]});
    }

    /** Fetch the list of competitors (names by id) */
    this.fetchCompetitors();

    /** Create an observable to return record details */
    let recordDetails$;
    recordDetails$ = this.activatedRoute.paramMap.pipe(
        switchMap((paramMap: ParamMap) => {
          const url = this.activatedRoute.snapshot.url;
          this.variationId = Number(paramMap.get('id'));
          this.region = paramMap.get('region');
          return forkJoin(
            this.ajaxDataService.getAjaxDetails('VariationDetails', this.variationId, this.region),
            this.ajaxDataService.getAjaxDetails('ProductDetails', this.variationId, this.region),
          );
      })
    );
    /** subscribe to the combined observables and update the form(s) on route change */
    recordDetails$
      .subscribe(
        (ajaxData: Array<any>) => {
console.log('ngOnInit ajaxData',ajaxData); // PIG's Debug 27/09/2019 11:47:46
          if (ajaxData[0].total) {
            this.variation = VariationFactory.create(ajaxData[0].dataset[0]);
          } else {
            this.handleError(new Error('No data record was returned for this variation'));
          }
          if (ajaxData[1].total) {
            this.product = ProductFactory.create(ajaxData[1].dataset[0]);
          } else {
            this.handleError(new Error('No data record was returned for parent product'));
          }
        },
        error => {
          this.handleError(error);
        }
      );

    this.fetchCompetitorResults({sortField:this.sortField,
                                 sortOrder:this.sortOrder});

  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */
  public onResizeEnd(event: ResizeEvent, panel: any, dragContainer: any) {
console.log('panel',panel); // PIG's Debug 10/07/2019 11:29:00
  }

/**
 * Public methods called from the template
 */
  public fetchCompetitorResults(loadEvent) {
console.warn('fetchCompetitorResults', loadEvent);
    if ((this.sortField && this.sortField !== loadEvent.sortField) ||
        (this.sortOrder && this.sortOrder !== loadEvent.sortOrder)) {
//      this.dataReset = false;
      this.sortField = loadEvent.sortField;
      this.sortOrder = loadEvent.sortOrder;
      this.competitorRecords = [];
      this.totalCompetitorRecords = 0;
//      setTimeout(() => {
//        this.dataReset = true; // refresh workaround for https://github.com/primefaces/primeng/issues/3585
//      },10);
    }
console.log('this.competitorRecords.length',this.competitorRecords.length); // PIG's Debug 07/02/2020 14:58:14
console.log('loadEvent.rows',loadEvent.rows); // PIG's Debug 07/02/2020 14:58:42
console.log('loadEvent.first',loadEvent.first); // PIG's Debug 07/02/2020 14:59:03
console.log('this.totalCompetitorRecords',this.totalCompetitorRecords); // PIG's Debug 07/02/2020 14:59:19
    if ((this.competitorRecords.length >= (loadEvent.first + loadEvent.rows)) ||  // we already have the requested data
        (this.totalCompetitorRecords && this.competitorRecords.length === this.totalCompetitorRecords)) {

      return;
    }

    const formData = new FormData();
console.log('formData',formData); // PIG's Debug 07/02/2020 15:00:23
    formData.append('pva_id', this.variationId);
    formData.append('region', this.region);
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('VariationCompetitors',
                                       this.competitorRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.competitorRecords.length),
                                       this.sortField,
                                       this.sortOrder,
                                       formData)
      .subscribe(
        (ajaxData) => {
          this.loadCompetitorRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods
 */
  private fetchCompetitors() {
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('CompetitorOptions')
      .subscribe(
        (ajaxData) => {
          this.loadCompetitors(ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods (helper functions)
 */
  private loadCompetitorRecords(loadEvent, ajaxData) {
console.log('loadCompetitorRecords',loadEvent,ajaxData); // PIG's Debug 07/02/2020 15:01:13
    this.totalCompetitorRecords = ajaxData.total;
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = CompetitorFactory.create(ajaxRow);
      this.competitorRecords.push(record);
console.log('loadCompetitorRecords',ajaxRow,record); // PIG's Debug 01/11/2019 11:37:30
    });

    if (this.recordsSelected.length < 1) {
      this.recordsSelected = this.competitorRecords.slice(0,5); // pick the top 5 initially when empty
    }

    this.loading = false;
  }

  private loadStockHistory(ajaxData) {
console.log('loadStockHistory',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    const dataPoints = [];
    ajaxData.dataset.forEach((ajaxRow,index) => {
      dataPoints.push(ajaxRow.sth_available_stock);
    });
    this.stockData.datasets[0].xndData = dataPoints;
    this.renderHistory(this.stockData, this.stockData.xndMode)
  }

  private loadAdwordHistory(ajaxData) {
console.log('loadAdwordHistory',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    const dataPoints = [];
    ajaxData.dataset.forEach((ajaxRow,index) => {
      dataPoints.push(ajaxRow.adh_clicks);
    });
    this.adwordData.datasets[0].xndData = dataPoints;
    this.renderHistory(this.adwordData, this.adwordData.xndMode)
  }

  private loadSalesHistory( ajaxData) {
console.log('loadSalesHistory',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    const dataPoints = [];
    ajaxData.dataset.forEach((ajaxRow,index) => {
      dataPoints.push(ajaxRow.slh_quantity);
    });
    this.salesData.datasets[0].xndData = dataPoints;
    this.salesOptions.scales.yAxes[0].ticks.min = Math.min(...dataPoints);
    this.salesOptions.scales.yAxes[0].ticks.max = Math.max(...dataPoints,1);
    this.renderHistory(this.salesData, this.salesData.xndMode)
  }

  private loadPriceHistory(ajaxData) {
console.log('loadPriceHistory',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    const dataPoints = [];
    ajaxData.dataset.forEach((ajaxRow,index) => {
      dataPoints.push(ajaxRow.prh_price);
    });
    this.salesData.datasets[1].xndData = dataPoints;
//    this.salesOptions.scales.yAxes[1].ticks.min = Math.min(...dataPoints);
//    this.salesOptions.scales.yAxes[1].ticks.max = Math.max(...dataPoints);
    this.setRoundedAxes(this.salesOptions.scales.yAxes[1].ticks,dataPoints);
    this.renderHistory(this.salesData, this.salesData.xndMode, 1)
  }

  private renderHistory(data: any, chartMode: string, index: number = 0) {
console.log('renderHistory',chartMode); // PIG's Debug 27/09/2019 15:59:58
    if (!data.datasets[index]) return; // TODO rationalise and tidy this bail out
    if (chartMode === 'M') {
      data.datasets[index].data = data.datasets[index].xndData.slice(47);
    } else if (chartMode === 'Q') {
      data.datasets[index].data = data.datasets[index].xndData.slice(38);
    } else {
      data.datasets[index].data = data.datasets[index].xndData;
    }
    const labels = [];
    data.datasets[index].data.forEach((entry,index) => {
      if (chartMode !== 'Y' || index % 4 === 0) {
        labels.push(index);
      } else {
        labels.push('');
      }
    });

    data.labels = labels.reverse();
//    this.stockChart.refresh();
//    this.salesChart.refresh();
    this.chartReset = false;
    setTimeout(() => {
      this.chartReset = true; // refresh workaround for https://github.com/primefaces/primeng/issues/3585
    },10);

  }

  private loadCompetitors(ajaxData) {
    ajaxData.dataset.forEach((ajaxRow) => {
      this.competitors[ajaxRow.cmp_id] = ajaxRow.cmp_name;
    });

    this.loading = false;
  }

  private setRoundedAxes(ticks,dataPoints) {
    const absoluteMin = Math.min(...dataPoints);
    const absoluteMax = Math.max(...dataPoints);
    const roundedMin = Math.floor(absoluteMin / 5) * 5;
    const roundedMax = Math.ceil(absoluteMax / 5) * 5;
    const stepSize = Math.max(Math.ceil(Math.round((roundedMax - roundedMin) / 10) / 5) * 5,1); // divide into 10 steps and round interval up to the nearest 5
console.log('rounding',absoluteMin,absoluteMax,roundedMin,roundedMax,stepSize); // PIG's Debug 11/10/2019 09:50:18
//    ticks.min = Math.round(absoluteMin /   10) * 10;
//    ticks.max = Math.round(absoluteMax / 10) * 10 + 10;
    ticks.min = Math.max(roundedMin - stepSize,0); // give a kickdown to the minimum so lowest level bars have some body
    ticks.max = roundedMax;
    ticks.stepSize = stepSize;
console.log('ticks.min/max',stepSize,ticks.min,ticks.max); // PIG's Debug 10/10/2019 17:56:59
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }


}
