/**
 * Import framework modules
 */
import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Import app specific elements
 */
import { AuthorisationService } from '../general-services/authorisation.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { DialogService }        from '../general-services/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginUsername:  string = null;
  public loginReset:     string = null;
  public loginPassword:  string = null;
  public repeatPassword: string = null;
  public  message:       string = null;
  private updateSerial:  number = 1567523926;

  public constructor(private configurationService: ConfigurationService,
                     private authorisationService: AuthorisationService,
                     private dialogService: DialogService,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private formBuilder: FormBuilder) {
    //this.createForm();
  }

  public ngOnInit() {
    let queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.reset && queryParams.reset.length == 42) { // length of md5 checksum + timestamp
        this.loginUsername = queryParams.reset;
        this.loginReset = queryParams.reset;
    }
  }

  public onSubmitLogin() {
    this.authorisationService.login(this.loginUsername,this.loginPassword)
      .subscribe(
        (ajaxData: any) => {
          if (ajaxData && ajaxData.localUser.token) {
            localStorage.setItem('localUser',JSON.stringify(ajaxData.localUser));
            Object.keys(ajaxData).forEach(settingsKey => {
              localStorage.setItem(settingsKey,JSON.stringify(ajaxData[settingsKey]));
            });
            this.router.navigate(['/']);
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.message = error.statusText;
          } else {
            this.handleError(error)
          }
        }
      );
  }

  public onSubmitReset() {
    this.authorisationService.reset(this.loginUsername)
      .subscribe(
        (ajaxData: any) => {
            this.message = ajaxData;
        },
        error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.message = error.statusText;
          } else {
            this.handleError(error)
          }
        }
      );
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error("handleError",error);
    return this.dialogService.reportError(error);
  }

}
