import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VariationComponent } from './variation/variation.component';
import { EditVariationComponent } from './edit-variation/edit-variation.component';
import { WeightingsComponent } from './weightings/weightings.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthoriseComponent } from './authorise/authorise.component';
import { Oauth2Component } from './oauth2/oauth2.component';
import { AuthorisationGuard } from './general-services/authorisation.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
    { path: '',
    component: MainComponent,
    canActivate: [AuthorisationGuard],
    children: [
      { path: 'brightpearl', component: AuthoriseComponent },
      { path: 'google', component: AuthoriseComponent },
      { path: 'slack', component: AuthoriseComponent },
      { path: 'oauth2', component: Oauth2Component },
      { path: 'weightings', component: WeightingsComponent },
      { path: '', component: DashboardComponent },
      { path: 'variation/:id/:region', component: VariationComponent },
      { path: 'variation/:id/:region/edit', component: EditVariationComponent },
      { path: '**', component: NotFoundComponent }
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

