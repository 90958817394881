/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { throwError as observableThrowError,  Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent,
				 HttpInterceptor,
				 HttpHandler,
				 HttpRequest,
				 HttpResponse,
				 HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * Import app specific elements
 */
import { ConfigurationService } from '../general-services/configuration.service';
import { AuthorisationService } from '../general-services/authorisation.service';

/**
 * Define the service
 */
@Injectable()
export class AuthenticatingHttpInterceptorService  implements HttpInterceptor {

/**
 * The constructor and init methods
 */
  constructor(private configurationService: ConfigurationService,
              private authorisationService: AuthorisationService,
              private router: Router) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler):
    				Observable<HttpEvent<any>> {

    let localUser = JSON.parse(localStorage.getItem('localUser'));

    if (localUser) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: 'Bearer ' + localUser.token
        },
        setParams: {
        }
      });
    }

    return next
      .handle(httpRequest)
      .pipe(
	      tap((httpResponse: any) => {
          if (httpResponse instanceof HttpResponse) {
            if (httpResponse.headers.get('JWT-Refresh')) {
              let localUser = JSON.parse(localStorage.getItem('localUser'));
              if (localUser) {
                localUser.token = httpResponse.headers.get('JWT-Refresh');
                localStorage.setItem('localUser',JSON.stringify(localUser));
              }
            }
          }
	      }),
        catchError(this.handleError())
      );

  }

  private handleError<T> (result?: T) {
    return (error: any): Observable<any> => { // TODO debug why Observable<T> produxes TS2322 error, posts suggest a typescript bug at time of writing
console.error("handleError",error,this.router.url);
      if (error instanceof HttpErrorResponse && error.status === 401) {
        const loginRoute = '/login';
        if (this.router.url !== loginRoute) {
          this.authorisationService.logout();
        }
        if (error.url.search('ajax_login') < 0) { // leave 401 errors to propagate from ajax_login as these indicate login problems
        	return /* Observable. */ empty();
        }
      }
    	return observableThrowError(error);
    };
  }
}
