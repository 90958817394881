/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Task {

  public id:                string;
  public variationId:       number;
  public live:              number;
  public bundle:            number;
  public region:            string;
  public name:              string;
  public code:              string;
  public variation:         string;
  public base:              number; // Base undiscounted price
  public price:             number;
  public prevPrice:         number;
  public discountCode:      string;
  public giftWithPurchase:  string;
  public availability:      number;
  public prevAvailability:  number;
  public date:              number;
  public priceTriggers:     Array<any>;
  public stockTriggers:     Array<any>;
  public adwordTriggers:    Array<any>;
  public impact:            number;
/* stock data pulled for development contemplation at this stage */
  public shopify:       number;
  public brightpearl:   number;
  public backorder:     number;
  public regions:     string;

  constructor(parameters: {
			id?:                string,
			variationId?:       number,
			live?:              number,
			bundle?:            number,
			region?:            string,
			name?:              string,
			code?:              string,
			variation?:         string,
			base?:              number,
			price?:             number,
			prevPrice?:         number,
			discountCode?:      string,
			giftWithPurchase?:  string,
			availability?:      number,
			prevAvailability?:  number,
   		date?:              number,
      priceTriggers?:     Array<any>;
      stockTriggers?:     Array<any>;
      adwordTriggers?:    Array<any>;
  		impact?:            number,
  		shopify?:           number,
  		brightpearl?:       number,
  		backorder?:         number,
  		regions?:           string,
    } = {}) {
	  this.id               = parameters.id || null;
	  this.variationId      = parameters.variationId || null;
	  this.live             = parameters.live || null;
	  this.bundle           = parameters.bundle || null;
	  this.region           = parameters.region || '';
	  this.name             = parameters.name || 'N/A';
	  this.code             = parameters.code || 'N/A';
	  this.variation        = parameters.variation || null;
	  this.base             = parameters.base || 0;
	  this.price            = parameters.price || 0;
	  this.prevPrice        = parameters.prevPrice || 0;
	  this.discountCode     = parameters.discountCode || '';
	  this.giftWithPurchase = parameters.giftWithPurchase || '';
	  this.availability     = parameters.availability || 0;
	  this.prevAvailability = parameters.prevAvailability || 0;
    this.date             = parameters.date || 0;
    this.impact           = parameters.impact || 0;
    this.priceTriggers    = parameters.priceTriggers || [];
    this.stockTriggers    = parameters.stockTriggers || [];
    this.adwordTriggers   = parameters.adwordTriggers || [];
	  this.shopify          = parameters.shopify || 0;
	  this.brightpearl      = parameters.brightpearl || 0;
	  this.backorder        = parameters.backorder || 0;
	  this.regions          = parameters.regions || '';
	}
}

export class TaskFactory {
	static create(tsk_record: AjaxTask) {
    const priceTriggers = [];
    const stockTriggers = [];
    const adwordTriggers = [];
    tsk_record.price_triggers.forEach(price_trigger => {
      priceTriggers.push({
        id:               price_trigger.prh_id,
        competitorId:     price_trigger.prh_cmp_id,
  			region:           price_trigger.prh_region,
  			date:             price_trigger.prh_date,
	  		base:     		    price_trigger.prh_base,
		  	price:     		    parseFloat(price_trigger.prh_price),
		  	prevPrice:     		parseFloat(price_trigger.prh_prev_price),
  			discountCode:     price_trigger.prh_discount_code,
	  		giftWithPurchase: price_trigger.prh_gift_with_purchase,
        availability:     price_trigger.prh_availability,
        prevAvailability: price_trigger.prh_prev_availability,
        impact:           price_trigger.impact,
        impactText:       price_trigger.impact_text,
      });
    });
    tsk_record.stock_triggers.forEach(stock_trigger => {
      stockTriggers.push({
        id:               stock_trigger.sth_id,
  			date:             stock_trigger.sth_date,
        stock:            stock_trigger.sth_stock,
        prevStock:        stock_trigger.sth_prev_stock,
        impact:           stock_trigger.impact,
        impactText:       stock_trigger.impact_text,
      });
    });
    tsk_record.adword_triggers.forEach(adword_trigger => {
      adwordTriggers.push({
        id:               adword_trigger.adh_id,
  			date:             adword_trigger.adh_date,
        clicks:           adword_trigger.adh_clicks,
        prevClicks:       adword_trigger.adh_prev_clicks,
        share:            adword_trigger.adh_share,
        prevShare:        adword_trigger.adh_prev_share,
        impact:           adword_trigger.impact,
        impactText:       adword_trigger.impact_text,
      });
    });
    tsk_record.textad_triggers.forEach(textad_trigger => {
      adwordTriggers.push({
        id:               textad_trigger.tah_id,
  			date:             textad_trigger.tah_date,
        sessions:         textad_trigger.tah_sessions,
        prevSessions:     textad_trigger.tah_prev_sessions,
        revenue:          textad_trigger.tah_revenue,
        prevRevenue:      textad_trigger.tah_prev_revenue,
        impact:           textad_trigger.impact,
        impactText:       textad_trigger.impact_text,
      });
    });
    return new Task({
    	id:               tsk_record.pva_id + '-' + tsk_record.prh_region,
    	variationId:      tsk_record.pva_id,
    	live:             tsk_record.pva_live,
			name:     		    tsk_record.prd_name,
			code:     		    tsk_record.pva_code,
			variation:        tsk_record.pva_variation,
    	bundle:           tsk_record.pva_bundle,
			region:           tsk_record.prh_region,
/*
			date:             tsk_record.prh_date,
			base:     		    tsk_record.prh_base,
			discountCode:     tsk_record.prh_discount_code,
			giftWithPurchase: tsk_record.prh_gift_with_purchase,
*/
			price:     		    tsk_record.prh_price,
			priceTriggers:    priceTriggers,
			stockTriggers:    stockTriggers,
			adwordTriggers:   adwordTriggers,
			impact:     	    tsk_record.impact,
			shopify:     	    tsk_record.pvs_shopify,
			brightpearl:      tsk_record.pvs_brightpearl,
			backorder:        tsk_record.pvs_backorder,
			regions:          tsk_record.pva_regions
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxTask {
  pva_id:                 number;
  pva_live:               number;
  prd_name:               string;
  pva_bundle:             number;
  pva_code:               string;
  pva_variation:          string;
  prh_region:             string;
/*
  prh_date:               number;
  prh_base:               number;
  prh_discount_code:      string;
  prh_gift_with_purchase: string;
*/
  prh_price:              number;
  todo_impact:            number;
  price_triggers: Array<{
    prh_id: number,
    prh_cmp_id: number,
    prh_region: string,
    prh_base: number,
    prh_date: number,
    prh_price: string,
    prh_prev_price: string,
    prh_discount: number,
    prh_discount_type: number,
    prh_discount_code: string,
    prh_gift_with_purchase: string,
    prh_availability: number,
    prh_prev_availability: number,
    impact: number,
    impact_text: string
  }>
  stock_triggers: Array<{
    sth_id: number,
    sth_date: number,
    sth_stock: number
    sth_prev_stock: number,
    impact: number,
    impact_text: string
  }>
  adword_triggers: Array<{
    adh_id: number,
    adh_date: number,
    adh_clicks: number
    adh_prev_clicks: number,
    adh_share: number
    adh_prev_share: number,
    adh_sessions: number
    adh_prev_sessions: number,
    impact: number,
    impact_text: string
  }>
  textad_triggers: Array<{
    tah_id: number,
    tah_date: number,
    tah_sessions: number
    tah_prev_sessions: number,
    tah_revenue: number
    tah_prev_revenue: number,
    impact: number,
    impact_text: string
  }>
  impact:                 number;
/* stock data pulled for development contemplation at this stage */
  pvs_shopify:     number;
  pvs_brightpearl: number;
  pvs_backorder:   number;
  pva_regions:     string;

}