/**
 * Import framework modules
 */
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

/**
 * Import app specific elements
 */
import { FilterCriteria } from "../data-definitions/global-interfaces"

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public development: boolean = false;

  public filterCriteriaBehaviorSubject: BehaviorSubject<FilterCriteria> = new BehaviorSubject({} as FilterCriteria);

  public currentFilterCriteria$ = this.filterCriteriaBehaviorSubject.asObservable();

  constructor() { }

  public getFilterCriteria() {
    return this.filterCriteriaBehaviorSubject.value;
  }

  public setFilterCriteria(filterCriteria) {
console.warn('filterCriteria',filterCriteria); // PIG's Debug 06/03/2020 15:29:14
    this.filterCriteriaBehaviorSubject.next(filterCriteria);
  }

}
