import { Injectable, ViewContainerRef, ApplicationRef } from '@angular/core';
import { Subject } from 'rxjs';

import { AppComponent } from '../app.component';
import { BrowserWindowService } from '../general-services/browser-window.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private rootViewContainerRef: ViewContainerRef;
  private confirmationSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private applicationRef: ApplicationRef,
              private browserWindowService: BrowserWindowService) {
    this.rootViewContainerRef = (this.applicationRef.components[0].instance as AppComponent).rootViewContainerRef
  }

  public reportError(error: any,
                     viewContainerRef?: ViewContainerRef) {
console.log('reportError',error); // PIG's Debug 16/09/2019 13:24:08
    let code = 'Application error';
    let text;
    if (error.status && error.error && typeof error.error === 'object') {
      code = error.error.error;
      text = error.error.text.replace(/<[^>]+>/g,'');
    } else if (error.error) {
      text = error.error;
    } else {
      text = error;
    }
    let message = '';
    if (error.url) { message += 'URL: ' + error.url.split('?')[0] + '\n'; }
    if (error.status) { message += 'HTTP Status: ' + error.status + '\n'; }
    if (code) { message += 'Error code: ' + code + '\n'; }
    message += 'Error text: ' + text;

    this.browserWindowService.window.alert(message);
  }

}