/**
 * Import framework modules
 */
import { Component, ComponentRef, OnInit, HostListener, ViewContainerRef, Renderer2 } from '@angular/core';
import { Observable, Subject ,  Subscription } from 'rxjs';


/**
 * Import 3rd party modules
 */
import { IModalDialog,
  			 IModalDialogOptions,
  			 IModalDialogButton,
  			 IModalDialogSettings, ModalDialogOnAction } from 'ngx-modal-dialog';

/**
 * Import app specific elements
 */
import { AjaxDataService, AjaxData } from '../../ajax-services/ajax-data.service';
import { DialogService } from '../../general-services/dialog.service';
import { AuthorisationService } from '../../general-services/authorisation.service';

/**
 * Define the component
 */
@Component({
  selector: 'app-remind',
  templateUrl: './remind.component.html',
  styleUrls: ['./remind.component.css']
})
export class RemindComponent implements IModalDialog {

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
console.log("esc",event);
    if (event.keyCode === 27) {
      this.completionSubject.next({} as AjaxData);
      this.closeDialogSubject.next();
    }
  }

/**
 * Public properties
 */
  public ajaxActive = false;

/**
 * Private properties
 */
  private completionSubject: Subject<AjaxData>; // returns data from the dialog when it completes
  private closeDialogSubject: Subject<void>; // triggered (e.g. by user hitting escape key) to signal parent to close this dialog
  private activeSubscriptions = new Subscription(); // hold an array of subscriptions to cancel on destroy


  private selectedDate: any = new Date;
  private priceTriggerIds: [];
  private stockTriggerIds: [];
  private variationIds: [];
  private actionButtons: IModalDialogButton[];

/**
 * The constructor and init/destroy methods
 */
  public constructor(private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
                     private authorisationService: AuthorisationService) {
    this.actionButtons = [
      { text: 'Cancel', onAction: () => {
        if (!this.ajaxActive) {
          this.completionSubject.next({} as AjaxData);
          return true;
        }
      }},
      { text: 'Confirm', onAction: () => {
        this.submit();
      }}
    ];
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.activeSubscriptions.unsubscribe();
  }

/**
 * Public methods
 */
  public dialogInit(reference: ComponentRef<IModalDialog>, options?: Partial<IModalDialogOptions<any>>) {
	  this.variationIds = options.data.variationIds;
	  this.priceTriggerIds = options.data.priceTriggerIds;
	  this.stockTriggerIds = options.data.stockTriggerIds;
	  this.completionSubject = options.data.completionSubject;
    this.closeDialogSubject = options.closeDialogSubject;

    /* subscribe to the logged in status so that if it changes to logged out the dialog will be cancelled and closed */
    this.activeSubscriptions.add(this.authorisationService.isLoggedIn
      .subscribe((isLoggedIn: boolean) => {
console.log("isLoggedInSubscription",isLoggedIn)
        if (!isLoggedIn) {
          this.completionSubject.next({} as AjaxData);
          this.closeDialogSubject.next();
        }
      })
    );
  }

/**
 * Private methods
 */
  submit() {
console.log('submit',this.selectedDate); // PIG's Debug 30/09/2019 14:13:47
    if (this.ajaxActive) {
      return;
    }
    this.ajaxActive = true;
    const formData: FormData = new FormData;
    this.priceTriggerIds.forEach(triggerId => {
      formData.append("prh_id[]", triggerId);
    });
    formData.append("date", (this.selectedDate.getTime() / 1000).toString());
    this.ajaxDataService.postAjaxDetails('DeferAction',formData)
      .subscribe(
        (ajaxResponse: AjaxData) => {
console.log("onSubmit",ajaxResponse);
          this.completionSubject.next(ajaxResponse);
          this.closeDialogSubject.next();
        },
        error => {
          this.handleError(error);
        });
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    this.completionSubject.next({} as AjaxData);
    this.closeDialogSubject.next();
    return this.dialogService.reportError(error);
  }


}
