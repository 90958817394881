/*
All material contained within is the intellectual property of Xanda Technology Ltd.
and as such may not be reproduced in any way shape or form without the prior consent
of Xanda Technology Ltd.
*/

/**
* Data structure for front end app
*/
export class Weighting {

  public id:                         string;
  public code:                       string;
  public name:                       string;
  public supplier:                   string;
  public region:                     string;
  public manual_override:            string;
  public brand_importance:           string;
  public new_product:                string;
  public recent_product:             string;
  public competitor_increases:       string;
  public competitor_decreases:       string;
  public competitor_undercuts:       string;
  public monthly_revenue:            string;
  public monthly_revenue_threshold:  string;
  public traffic_level:              string;
  public traffic_level_threshold:    string;
  public out_of_stock:               string;
  public restocked:                  string;
  public stock_exceeds:              string;
  public stock_exceeds_threshold:    string;
  public stock_fails:                string;
  public stock_fails_threshold:      string;
  public advert_position:            string;

  constructor(parameters: {
    id?:                         string,
    code?:                       string,
    name?:                       string,
    supplier?:                   string,
    region?:                     string,
    manual_override?:            string,
    brand_importance?:           string,
    new_product?:                string,
    recent_product?:             string,
    competitor_increases?:       string,
    competitor_decreases?:       string,
    competitor_undercuts?:       string,
    monthly_revenue?:            string,
    monthly_revenue_threshold?:  string,
    traffic_level?:              string,
    traffic_level_threshold?:    string,
    out_of_stock?:               string,
    restocked?:                  string,
    stock_exceeds?:              string,
    stock_exceeds_threshold?:    string,
    stock_fails?:                string,
    stock_fails_threshold?:      string,
    advert_position?:            string,
  } = {}) {
    this.id                         = parameters.id                         || '';
    this.code                       = parameters.code                       || '';
    this.name                       = parameters.name                       || '';
    this.supplier                   = parameters.supplier                   || '';
    this.region                     = parameters.region                     || '';
    this.manual_override            = parameters.manual_override            || '';
    this.brand_importance           = parameters.brand_importance           || '';
    this.new_product                = parameters.new_product                || '';
    this.recent_product             = parameters.recent_product             || '';
    this.competitor_increases       = parameters.competitor_increases       || '';
    this.competitor_decreases       = parameters.competitor_decreases       || '';
    this.competitor_undercuts       = parameters.competitor_undercuts       || '';
    this.monthly_revenue            = parameters.monthly_revenue            || '';
    this.monthly_revenue_threshold  = parameters.monthly_revenue_threshold  || '';
    this.traffic_level              = parameters.traffic_level              || '';
    this.traffic_level_threshold    = parameters.traffic_level_threshold    || '';
    this.out_of_stock               = parameters.out_of_stock               || '';
    this.restocked                  = parameters.restocked                  || '';
    this.stock_exceeds              = parameters.stock_exceeds              || '';
    this.stock_exceeds_threshold    = parameters.stock_exceeds_threshold    || '';
    this.stock_fails                = parameters.stock_fails                || '';
    this.stock_fails_threshold      = parameters.stock_fails_threshold      || '';
    this.advert_position            = parameters.advert_position            || '';
  }
}

export class WeightingFactory {
  static create(pvw_record: AjaxWeighting) {

    return new Weighting({
      id:                         pvw_record.pvw_id,
      code:                       pvw_record.pva_code,
      name:                       pvw_record.prd_name,
      supplier:                   pvw_record.sup_name,
      region:                     pvw_record.pvw_region,
      manual_override:            pvw_record.pvw_manual_override,
      brand_importance:           pvw_record.pvw_brand_importance,
      new_product:                pvw_record.pvw_new_product,
      recent_product:             pvw_record.pvw_recent_product,
      competitor_increases:       pvw_record.pvw_competitor_increases,
      competitor_decreases:       pvw_record.pvw_competitor_decreases,
      competitor_undercuts:       pvw_record.pvw_competitor_undercuts,
      monthly_revenue:            pvw_record.pvw_monthly_revenue,
      monthly_revenue_threshold:  pvw_record.pvw_monthly_revenue_threshold,
      traffic_level:              pvw_record.pvw_traffic_level,
      traffic_level_threshold:    pvw_record.pvw_traffic_level_threshold,
      out_of_stock:               pvw_record.pvw_out_of_stock,
      restocked:                  pvw_record.pvw_restocked,
      stock_exceeds:              pvw_record.pvw_stock_exceeds,
      stock_exceeds_threshold:    pvw_record.pvw_stock_exceeds_threshold,
      stock_fails:                pvw_record.pvw_stock_fails,
      stock_fails_threshold:      pvw_record.pvw_stock_fails_threshold,
      advert_position:            pvw_record.pvw_advert_position,
    });
  }
}

/**
* Record structure from back end end
*/
export interface AjaxWeighting {
  pvw_id:                         string;
  pva_code:                       string;
  prd_name:                       string;
  sup_name:                       string;
  pvw_region:                     string;
  pvw_manual_override:            string;
  pvw_brand_importance:           string;
  pvw_new_product:                string;
  pvw_recent_product:             string;
  pvw_competitor_increases:       string;
  pvw_competitor_decreases:       string;
  pvw_competitor_undercuts:       string;
  pvw_monthly_revenue:            string;
  pvw_monthly_revenue_threshold:  string;
  pvw_traffic_level:              string;
  pvw_traffic_level_threshold:    string;
  pvw_out_of_stock:               string;
  pvw_restocked:                  string;
  pvw_stock_exceeds:              string;
  pvw_stock_exceeds_threshold:    string;
  pvw_stock_fails:                string;
  pvw_stock_fails_threshold:      string;
  pvw_advert_position:            string;
}
