export function capitalise(string: string) {
  return string[0].toUpperCase() + string.substr(1);
}

export function containsWord(sentence: string, word: string) {
    return sentence.split(' ').indexOf(word) !== -1;
}

export function reverseString(string: string) {
    return string.split("").reverse().join("");
}
