/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';
import { DOCUMENT } from "@angular/common";
import { Subject, Subscription, forkJoin } from 'rxjs';
import { switchMap, tap, takeUntil, first } from 'rxjs/operators';

/**
 * Import 3rd party modules
 */
import { ResizeEvent } from 'angular-resizable-element';
import { UIChart } from 'primeng/components/chart/chart';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from '../general-services/browser-window.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { AjaxData, AjaxDataService } from '../ajax-services/ajax-data.service';
import { DataExportService } from '../ajax-services/data-export.service';
import { DialogService } from '../general-services/dialog.service';
import { RegionDescriptions, RegionCurrencies, WeightingsRecords, BrandSettingsColumns, WeightingsColumns2 } from "../data-definitions/global-constants"
import { Variation, VariationFactory } from '../data-definitions/variation';
import { Product, ProductFactory } from '../data-definitions/product';
import { Weighting, WeightingFactory } from '../data-definitions/weighting';

/**
 * Define the component
 */
@Component({
  selector: 'app-weightings',
  templateUrl: './weightings.component.html',
  styleUrls: ['./weightings.component.css']
})
export class WeightingsComponent implements OnInit {

/**
 * Public properties
 */
  public math = Math; // make math available in the template
  // TOGO public region = "Mars";
  public regions = [];
  public regionDescriptions = RegionDescriptions;
  public regionCurrencies = RegionCurrencies;
  public variationId;
  public variation: Variation;
  public product: Product;
  public brandRecords = [];
  public weightingsRecords = WeightingsRecords;
  public totalBrandRecords = 0;
  public brandsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 34, rows: 10, columns: BrandSettingsColumns };
  public weightingsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '500px', virtualRowHeight: 34, rows: 10, columns: WeightingsColumns2 };
  public loading = true;
  public selectedRegion: null;


  public locked = {weights: false,
                   base: false,
                   brand: false,
                   product: false,
                   region: false};

/**
 * Private properties
 */
  private windowRef: Window;
  private onDestroy$ = new Subject();
  private sortField = '';
  private sortOrder = 1;

/**
 * External linkage
 */
  @ViewChild('weightingsForm', { static: true }) public weightingsForm: NgForm;

/**
 * The constructor and init/destroy methods
 */
  public constructor(private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private browserWindowService: BrowserWindowService,
                     private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dataExportService: DataExportService,
                     private dialogService: DialogService,
   @Inject(DOCUMENT) private documentRef: Document) {

  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url;
    this.variationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.regions = [];
    Object.keys(RegionDescriptions).forEach(key => {
      this.regions.push({label: RegionDescriptions[key], value: key});
   //   !isNaN(Number(RegionDescriptions[key]))
    });

    this.fetchWeightingsResults();
    this.fetchBrandResults({sortField:this.sortField,
                            sortOrder:this.sortOrder});

  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */
  onChangeInput(event, field?: string) {
console.log('onChangeInput',event,field); // PIG's Debug 24/09/2019 16:05:06
//    this.weightingsRecords = [];
    this.brandRecords = [];
    this.totalBrandRecords = 0;
    this.fetchWeightingsResults();
    this.fetchBrandResults({sortField:this.sortField,
                            sortOrder:this.sortOrder});
  }

  public onUpdateBrands(event: any,form: NgForm) {
console.log('onUpdateWeightings',event,form); // PIG's Debug 10/07/2019 11:29:00
    const formData: FormData = this.ajaxDataService.ajaxFormDataFromGroup(form.form,true);
    formData.append('pva_id', this.variationId);
    if (this.selectedRegion) {
      formData.append('region', this.selectedRegion);
    } else {
      formData.append('region', null);
    }
    this.ajaxDataService.postAjaxDetails('UpdateBrands',formData)
      .subscribe(
        (ajaxResponse: AjaxData) => {
alert("Sent");
        },
        error => {
          this.handleError(error);
        });
  }

  public onUpdateWeightings(event: any,form: NgForm) {
console.log('onUpdateWeightings',event,form); // PIG's Debug 10/07/2019 11:29:00
    const formData: FormData = this.ajaxDataService.ajaxFormDataFromGroup(form.form,true);
    formData.append('pva_id', this.variationId);
    if (this.selectedRegion) {
      formData.append('region', this.selectedRegion);
    } else {
      formData.append('region', null);
    }
    this.ajaxDataService.postAjaxDetails('UpdateWeighting',formData,this.variationId)
      .subscribe(
        (ajaxResponse: AjaxData) => {
alert("Sent");
        },
        error => {
          this.handleError(error);
        });
  }

  public onToggleLock(event: any,form: NgForm,column: string) {
console.log('onToggleLock',event,form,column); // PIG's Debug 10/07/2019 11:29:00
    Object.keys(form.form.controls).forEach(formKey => {
      if (formKey.endsWith('_' + column))
      {
        const formControl = form.form.get(formKey);
console.log('onToggleLock',formControl); // PIG's Debug 06/11/2019 14:56:23
        if (formControl.disabled) {
          formControl.enable();
        } else {
          formControl.disable();
        }
      }
    });
    this.locked[column] = !this.locked[column];
  }

  public onClickErase(event: any,form: NgForm,column: string) {
console.log('onClickErase',event,form,column); // PIG's Debug 10/07/2019 11:29:00
    if (this.locked[column]) {
      return false;
    }
    Object.keys(form.form.controls).forEach(formKey => {
      if (formKey.endsWith('_' + column))
      {
        const formControl = form.form.get(formKey);
        formControl.setValue(null);
        formControl.markAsDirty();
      }
    });
  }

  public onClickDownload1(event: Event) {
    this.ajaxDataService.getAjaxSearch('WeightingDownload')
      .subscribe(
        (ajaxData) => {
          const records = this.loadWeightingRecords(ajaxData);
          this.dataExportService.downloadFile(records, 'weightings-download');
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onClickDownload2(event: Event) {
    this.ajaxDataService.getAjaxSearch('BrandsDownload')
      .subscribe(
        (ajaxData) => {
          const records = this.loadWeightingRecords(ajaxData);
          this.dataExportService.downloadFile(records, 'brands-download');
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onResizeEnd(event: ResizeEvent, panel: any, dragContainer: any) {
console.log('panel',panel); // PIG's Debug 10/07/2019 11:29:00
  }

/**
 * Public methods called from the template
 */
  public fetchWeightingsResults() {
    this.loading = true;
console.log('fetchWeightingsResults'); // PIG's Debug 31/10/2019 16:32:54
/*
    this.ajaxDataService.getAjaxSearch('BrandWeights',
                                       this.brandRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.brandRecords.length),
                                       null,
                                       null,
                                       formData)
*/
    this.ajaxDataService.getAjaxDetails('WeightingDetails', this.variationId, this.selectedRegion)
      .subscribe(
        (ajaxData) => {
          this.loadWeightingData(ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public fetchBrandResults(loadEvent) {
console.log('fetchBrandResults'); // PIG's Debug 31/10/2019 16:32:54
    this.loading = true;
/*
    this.ajaxDataService.getAjaxSearch('BrandWeights',
                                       this.brandRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.brandRecords.length),
                                       null,
                                       null,
                                       formData)
*/
    this.ajaxDataService.getAjaxDetails('BrandWeights', this.variationId, this.selectedRegion)
      .subscribe(
        (ajaxData) => {
          this.loadBrandRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods
 */

/**
 * Private methods (helper functions)
 */
  private loadWeightingRecords(ajaxData) {
    const weightingRecords = [];
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = WeightingFactory.create(ajaxRow);
      weightingRecords.push(record);
    });
    return weightingRecords;
  }

  private loadBrandRecords(loadEvent, ajaxData) {
console.log('loadBrandRecords',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    this.totalBrandRecords = ajaxData.total;
    ajaxData.dataset.forEach((ajaxRow) => {
      // const record = TaskFactory.create(ajaxRow);
      const record = {id: ajaxRow.sup_id,name: ajaxRow.sup_name,pvw_brand_importance: ajaxRow.pvw_brand_importance,pvw_brand_importance_ads: ajaxRow.pvw_brand_importance_ads};
      this.brandRecords.push(record);
    });

    this.loading = false;
console.log('this.brandRecords',this.brandRecords); // PIG's Debug 03/02/2022 13:38:08
  }

  private loadWeightingData(ajaxData) {
console.log('loadWeightingData',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    //TOGO this.totalBrandRecords = ajaxData.total;
    // reset the fields
    ["weights","base","brand","product","region"].forEach(column => {
      this.weightingsRecords.forEach(record => {
        record[column] = null;
        if (record.hasThreshold) {
          record[column + "_threshold"] = null;
        }
      });
    })
    ajaxData.dataset.forEach((ajaxRow) => {
      let column = "";
      if (ajaxRow.pvw_weights) {
        column = "weights"
      }
      else if (ajaxRow.pvw_region && ajaxRow.pvw_pva_id) {
        column = "region"
      }
      else if (ajaxRow.pvw_sup_id) {
        column = "brand"
      }
      else if (ajaxRow.pvw_pva_id) {
        column = "product"
      }
      else {
        column = "base"
      }
      this.weightingsRecords.forEach(record => {
        record[column] = ajaxRow[record.field];
        if (record.hasThreshold) {
          record[column + "_threshold"] = ajaxRow[record.field  + "_threshold"];
        }
      });

    });

    this.loading = false;
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }

}


