import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserWindowService {

  constructor() { }
  get window() {
      return window;
  }
}
