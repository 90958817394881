/**
 * Import framework modules
 */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, first } from "rxjs/operators";
import { Subject, Subscription } from 'rxjs';

/**
 * Import 3rd party modules
 */

/**
 * Import app specific elements
 */
import { AjaxDataService } from '../ajax-services/ajax-data.service';
import { BrowserWindowService } from '../general-services/browser-window.service';
import { DialogService } from '../general-services/dialog.service';
import { AjaxData } from '../ajax-services/ajax-data.service';
import { capitalise, containsWord } from '../general-functions/string-functions';

/**
 * Define the component
 */
@Component({
  selector: 'app-authorise',
  templateUrl: './authorise.component.html',
  styleUrls: ['./authorise.component.css']
})
export class AuthoriseComponent implements OnInit {

/**
 * Public properties
 */
  public authorised = false;

/**
 * Private properties
 */
  private onDestroy$ = new Subject();

/**
 * The constructor and init methods
 */
  public constructor(private activatedRoute: ActivatedRoute,
                     private browserWindowService: BrowserWindowService,
                     private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService) {
  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url
console.log('url',url); // PIG's Debug 30/09/2019 17:44:27
    this.authorise(url.pop());
  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }


/**
 * Event handlers
 */
  private authorise(url) {
console.log('url',url); // PIG's Debug 30/09/2019 17:46:42
    const formData = new FormData();
    formData.append('application', url.path);
    this.ajaxDataService.postAjaxDetails('Authorise',formData) //  + capitalise(url.path))
      .subscribe(
        (ajaxData: AjaxData) => {
console.log("fetchSearchResults",ajaxData);
          if (typeof ajaxData === 'string') {
            this.browserWindowService.window.open(ajaxData,'_blank')
          } else {
            this.authorised = true;
          }
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.log("handleError",error);
    return this.dialogService.reportError(error);
  }


}
