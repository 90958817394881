/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Subject, Subscription } from 'rxjs';
import { switchMap, tap, takeUntil, first } from 'rxjs/operators';

/**
 * Import 3rd party modules
 */
import { ResizeEvent } from 'angular-resizable-element';
import { faTimes, faCalendarAlt, faLongArrowAltUp, faLongArrowAltDown,
         faChartArea, faPoundSign } from '@fortawesome/free-solid-svg-icons';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from '../general-services/browser-window.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { AjaxData, AjaxDataService } from '../ajax-services/ajax-data.service';
import { DataExportService } from '../ajax-services/data-export.service';
import { ActionPopupService } from '../general-services/action-popup.service';;
import { DialogService } from '../general-services/dialog.service';
import { StateDescriptions,
         TypeDescriptions,
         RegionDescriptions,
         RegionCurrencies,
         actionsColumns,
         adwordsColumns,
         reviewsColumns } from "../data-definitions/global-constants"
import { FilterCriteria } from "../data-definitions/global-interfaces"
import { Task, TaskFactory } from '../data-definitions/task';
import { Stock, StockFactory } from '../data-definitions/stock';

/**
 * Define the component
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

/**
 * Public properties
 */
  public regionDescriptions = RegionDescriptions;
  public regionCurrencies = RegionCurrencies;
  public actionRecords = [];
  public totalActionRecords = 0;
  public adwordRecords = [];
  public totalAdwordRecords = 0;
  public reviewRecords = [];
  public totalReviewRecords = 0;
  public regions = [];
  public states = [];
  public types = [];
  public competitors = [];
  public suppliers = [];
  public recordsSelected = [];

  public filterCriteria: FilterCriteria = {
    selectedRegion: null,
    selectedSupplier: null,
    searchTerm: null,
    selectedState: null,
    selectedType: null,
    cutoffDate: null,
  }
  public loading = true;
  public actionsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 37, rows: 10, columns: actionsColumns };
  public adwordsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 37, rows: 10, columns: adwordsColumns };
  public reviewsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '100px', virtualRowHeight: 37, rows: 10, columns: reviewsColumns };
  public lazyLoadOnInit = false;
  public dataReset: boolean = true;

  public faTimes = faTimes;
  public faCalendarAlt = faCalendarAlt;
  public faLongArrowAltUp = faLongArrowAltUp;
  public faLongArrowAltDown = faLongArrowAltDown;
  public faChartArea = faChartArea;
  public faPoundSign = faPoundSign;


/**
 * Private properties
 */
  private windowRef: Window;
  private sortField = '';
  private sortOrder = 1;
  private onDestroy$ = new Subject();

/**
 * External linkage
 */
  @ViewChild('actionsContainer', { static: true }) actionsContainer: any;
  @ViewChild('actionsTable', { static: false }) actionsTable: any;

/**
 * The constructor and init/destroy methods
 */
  public constructor(private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private browserWindowService: BrowserWindowService,
                     private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dataExportService: DataExportService,
                     private actionPopupService: ActionPopupService,
                     private dialogService: DialogService,
   @Inject(DOCUMENT) private documentRef: Document) {
  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url;
    const sortColumn = this.actionsPanelMeta.columns.find(column => { return column.sortOrder === 1 || column.sortOrder === -1 });
    if (sortColumn) {
      this.sortField = sortColumn.field;
      this.sortOrder = sortColumn.sortOrder;
    }
    this.windowRef = this.browserWindowService.window;

    this.regions = [];
    Object.keys(RegionDescriptions).forEach(key => {
      this.regions.push({label: RegionDescriptions[key], value: key});
   //   !isNaN(Number(RegionDescriptions[key]))
    });

    this.states = [];
    Object.keys(StateDescriptions).forEach(key => {
      this.states.push({label: StateDescriptions[key], value: key});
   //   !isNaN(Number(RegionDescriptions[key]))
    });
    this.types = [];
    Object.keys(TypeDescriptions).forEach(key => {
      this.types.push({label: TypeDescriptions[key], value: key});
   //   !isNaN(Number(RegionDescriptions[key]))
    });

    this.fetchSuppliers();
    this.fetchCompetitors();

    this.filterCriteria = this.configurationService.getFilterCriteria();
    if (!this.filterCriteria.cutoffDate) {
      this.filterCriteria.cutoffDate = new Date();
      this.filterCriteria.cutoffDate.setDate(this.filterCriteria.cutoffDate.getDate() - 7);
      this.configurationService.setFilterCriteria(this.filterCriteria);
    }

    this.configurationService.currentFilterCriteria$
      .subscribe((filterCriteria: FilterCriteria) => {
console.log('filterCriteria',filterCriteria); // PIG's Debug 20/09/2019 13:37:53
        this.filterCriteria = filterCriteria;
      });

    this.fetchActionResults({sortField:this.sortField,
                             sortOrder:this.sortOrder});

    this.fetchAdwordResults({sortField:this.sortField,
                             sortOrder:this.sortOrder});
  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */
  onChangeInput(event, field?: string) {
console.log('onChangeInput',event,field); // PIG's Debug 24/09/2019 16:05:06
    if (field == 'state' && event.value === 'all' && !this.filterCriteria.selectedRegion) {
      this.filterCriteria.selectedRegion = 'gb'; // force a country on the all products selection
    }
    if (field == 'region' && event.value === null && this.filterCriteria.selectedState == 'all') {
      this.filterCriteria.selectedState = 'active'; // force state away from all if region cancelled
    }
    this.configurationService.setFilterCriteria(this.filterCriteria);
    this.actionRecords = [];
    this.totalActionRecords = 0;
    this.fetchActionResults({sortField:this.sortField,
                             sortOrder:this.sortOrder});
    this.adwordRecords = [];
    this.totalAdwordRecords = 0;
    this.fetchAdwordResults({sortField:this.sortField,
                             sortOrder:this.sortOrder});
  }

  public onResizeEnd(event: ResizeEvent, panel: any, dragContainer: any) {
console.log('panel',panel); // PIG's Debug 10/07/2019 11:29:00
  }

  public onClickRemind(event: Event, recordClicked: any, triggerClicked?: any) {
    const priceTriggerIds = [];
    let variationIds = [];
    if (triggerClicked) {
      priceTriggerIds.push(triggerClicked.id);
    } else {
      this.recordsSelected.forEach(record => {
//        const ids = record.priceTriggers.map(element => {return element.id;});
        const triggers = record.priceTriggers.filter(element => element.selected);
        const ids = triggers.map(element => {return element.id;});
        priceTriggerIds.push(...ids);
      });
//      const ids = recordClicked.priceTriggers.map(element => {return element.id;});
//      priceTriggerIds.push(...ids);
      variationIds = this.recordsSelected.map(element => {return element.id;});
    }
    if (!variationIds.includes(recordClicked.id)) {
      variationIds.push(recordClicked.id); // in case the clicked variation isn't already in the pre-selected list
    }
    this.actionPopupService.remindPopup(variationIds,priceTriggerIds)
      .pipe(first())
      .subscribe(
        (ajaxData: AjaxData) => {
console.log('ajaxData',ajaxData); // PIG's Debug 30/09/2019 14:10:40
					this.recordsSelected = [];
          this.actionRecords = [];
          this.totalActionRecords = 0;
          this.fetchActionResults({sortField:this.sortField,
                                   sortOrder:this.sortOrder});
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onClickDismiss(event: Event, recordClicked: any, triggerClicked?: any) {
console.log('onClickDismiss',recordClicked,triggerClicked); // PIG's Debug 25/02/2020 15:45:13
    const priceTriggerIds = [];
    let variationIds = [];
    if (triggerClicked) {
      priceTriggerIds.push(triggerClicked.id);
    } else {
      this.recordsSelected.forEach(record => {
//        const ids = record.priceTriggers.map(element => {return element.id;});
        const triggers = record.priceTriggers.filter(element => element.selected);
        const ids = triggers.map(element => {return element.id;});
        priceTriggerIds.push(...ids);
      });
//      const ids = recordClicked.priceTriggers.map(element => {return element.id;});
//      priceTriggerIds.push(...ids);
      variationIds = this.recordsSelected.map(element => {return element.id;});
    }
    if (!variationIds.includes(recordClicked.id)) {
      variationIds.push(recordClicked.id); // in case the clicked variation isn't already in the pre-selected list
    }
console.log('onClickRemind',recordClicked,this.recordsSelected,variationIds,priceTriggerIds); // PIG's Debug 04/11/2019 09:55:58
    const formData: FormData = new FormData;
    priceTriggerIds.forEach(triggerId => {
      formData.append("prh_id[]", triggerId);
    });
    formData.append("dismiss", "dismiss");
    this.ajaxDataService.postAjaxDetails('DeferAction',formData)
      .subscribe(
        (ajaxResponse: AjaxData) => {
console.log("onDismiss",ajaxResponse);
          this.actionRecords = [];
          this.totalActionRecords = 0;
          this.fetchActionResults({sortField:this.sortField,
                                   sortOrder:this.sortOrder});
        },
        error => {
          this.handleError(error);
        });
  }

  public onClickRefer(event: Event, recordClicked: any) {
    const priceTriggerIds = [];
    this.recordsSelected.forEach(record => {
      const ids = record.priceTriggers.map(element => {return element.id;});
      priceTriggerIds.push(...ids);
    });
    const ids = recordClicked.priceTriggers.map(element => {return element.id;});
    priceTriggerIds.push(...ids);
    const variationIds = this.recordsSelected.map(element => {return element.id;});
    if (!variationIds.includes(recordClicked.id)) {
      variationIds.push(recordClicked.id); // in case the clicked variation isn't already in the pre-selected list
    }
/*
    const priceTriggerIds = [];
    let variationIds = [];
    this.recordsSelected.forEach(record => {
      const triggers = record.priceTriggers.filter(element => element.selected);
      const ids = triggers.map(element => {return element.id;});
      priceTriggerIds.push(...ids);
    });
    variationIds = this.recordsSelected.map(element => {return element.id;});
    if (!variationIds.includes(recordClicked.id)) {
      variationIds.push(recordClicked.id); // in case the clicked variation isn't already in the pre-selected list
    }
*/
    this.actionPopupService.referPopup(variationIds,priceTriggerIds)
      .pipe(first())
      .subscribe(
        (ajaxData: AjaxData) => {
console.log('onClickRefer ajaxData',ajaxData); // PIG's Debug 30/09/2019 14:10:40
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onClickNotes(event: Event, recordClicked: any) {
    const priceTriggerIds = [];
    this.actionPopupService.notesPopup(recordClicked.id)
      .pipe(first())
      .subscribe(
        (ajaxData: AjaxData) => {
console.log('onClickNotes ajaxData',ajaxData); // PIG's Debug 30/09/2019 14:10:40
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onClickDownload(event: Event) {
    this.ajaxDataService.getAjaxSearch('StockDownload')
      .subscribe(
        (ajaxData) => {
          const records = this.loadStockRecords(ajaxData);
          this.dataExportService.downloadFile(records, 'test-download');
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public onRowSelect(event: any,state: boolean) {
console.log('onRowSelect',event,state,this.recordsSelected);
		event.data.priceTriggers.forEach(element => element.selected = state);
		event.data.stockTriggers.forEach(element => element.selected = state);
  }

  public onTriggerSelect(event: Event,row: any) {
console.log('onSelectTrigger',event,row,this.recordsSelected);
    const priceTrigger = row.priceTriggers.find(trigger => { return trigger.selected === true });
    const stockTrigger = row.stockTriggers.find(trigger => { return trigger.selected === true });
console.log('onSelectTrigger',priceTrigger,stockTrigger);
		if (event) {
			const recordSelected = this.recordsSelected.find(element => element === row);
			if (!recordSelected) {
				this.recordsSelected.push(row);
				this.recordsSelected = [...this.recordsSelected];
			}
		}
		if (!event && !priceTrigger && !stockTrigger) {
			this.recordsSelected = this.recordsSelected.filter(element => element !== row);
		}

  }

  public stopPropagation(event: Event) {
console.log('stopPropagation');
		event.stopPropagation();
  }

/**
 * Public methods called from the template
 */
  public fetchActionResults(loadEvent) {
console.warn('fetchActionResults', loadEvent);
    if ((this.sortField && this.sortField !== loadEvent.sortField) ||
        (this.sortOrder && this.sortOrder !== loadEvent.sortOrder)) {
      this.sortField = loadEvent.sortField;
      this.sortOrder = loadEvent.sortOrder;
      this.actionRecords = [];
      this.totalActionRecords = 0;
    }
    if ((this.actionRecords.length >= (loadEvent.first + loadEvent.rows)) ||  // we already have the requested data
        (this.totalActionRecords && this.actionRecords.length === this.totalActionRecords)) {
        return;
    }

    const formData = new FormData();
    if (this.filterCriteria.searchTerm) { formData.append('search_term', this.filterCriteria.searchTerm); }
    if (this.filterCriteria.selectedRegion) { formData.append('region',this.filterCriteria.selectedRegion); }
    if (this.filterCriteria.selectedSupplier) { formData.append('sup_id',this.filterCriteria.selectedSupplier.toString()); }
    if (this.filterCriteria.selectedState) { formData.append('state',this.filterCriteria.selectedState); }
    if (this.filterCriteria.selectedType) { formData.append('type',this.filterCriteria.selectedType); }
    if (this.filterCriteria.cutoffDate) { formData.append('cutoff_date',(this.filterCriteria.cutoffDate.getTime() / 1000).toString()); }
console.log('this.filterCriteria.cutoffDate',this.filterCriteria.cutoffDate); // PIG's Debug 03/03/2020 13:29:21
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('DashboardActions',
                                       this.actionRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.actionRecords.length),
                                       this.sortField,
                                       this.sortOrder,
                                       formData)
      .subscribe(
        (ajaxData) => {
          this.loadActionRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public fetchAdwordResults(loadEvent) {
console.warn('fetchAdwordResults', loadEvent);
    if ((this.sortField && this.sortField !== loadEvent.sortField) ||
        (this.sortOrder && this.sortOrder !== loadEvent.sortOrder)) {
      this.sortField = loadEvent.sortField;
      this.sortOrder = loadEvent.sortOrder;
      this.adwordRecords = [];
      this.totalAdwordRecords = 0;
    }
    if ((this.adwordRecords.length >= (loadEvent.first + loadEvent.rows)) ||  // we already have the requested data
        (this.totalAdwordRecords && this.adwordRecords.length === this.totalAdwordRecords)) {
        return;
    }

    const formData = new FormData();
    if (this.filterCriteria.searchTerm) { formData.append('search_term', this.filterCriteria.searchTerm); }
    if (this.filterCriteria.selectedRegion) { formData.append('region',this.filterCriteria.selectedRegion); }
    if (this.filterCriteria.selectedSupplier) { formData.append('sup_id',this.filterCriteria.selectedSupplier.toString()); }
    if (this.filterCriteria.selectedState) { formData.append('state',this.filterCriteria.selectedState); }
    if (this.filterCriteria.selectedType) { formData.append('type',this.filterCriteria.selectedType); }
    if (this.filterCriteria.cutoffDate) { formData.append('cutoff_date',(this.filterCriteria.cutoffDate.getTime() / 1000).toString()); }
console.log('this.filterCriteria.cutoffDate',this.filterCriteria.cutoffDate); // PIG's Debug 03/03/2020 13:29:21
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('DashboardAdwords',
                                       this.adwordRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.adwordRecords.length),
                                       this.sortField,
                                       this.sortOrder,
                                       formData)
      .subscribe(
        (ajaxData) => {
          this.loadAdwordRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public fetchReviewResults(loadEvent) {
    if ((this.actionRecords.length >= (loadEvent.first + loadEvent.rows)) ||  // we already have the requested data
        (this.totalActionRecords && this.actionRecords.length === this.totalActionRecords)) {
        return;
    }

return;

    const formData = new FormData();
    if (this.filterCriteria.searchTerm) { formData.append('search_term', this.filterCriteria.searchTerm); }
    if (this.filterCriteria.selectedRegion) { formData.append('region',this.filterCriteria.selectedRegion); }
    if (this.filterCriteria.selectedSupplier) { formData.append('sup_id',this.filterCriteria.selectedSupplier.toString()); }
    if (this.filterCriteria.selectedState) { formData.append('state',this.filterCriteria.selectedState); }
    if (this.filterCriteria.cutoffDate) { formData.append('cutoff_date',(this.filterCriteria.cutoffDate.getTime() / 1000).toString()); }
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('DashboardReviews',
                                       this.reviewRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.reviewRecords.length),
                                       this.sortField,
                                       this.sortOrder,
                                       formData)
      .subscribe(
        (ajaxData) => {
          this.loadReviewRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods
 */
  private fetchSuppliers() {
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('SupplierOptions')
      .subscribe(
        (ajaxData) => {
          this.loadSupplierOptions(ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private fetchCompetitors() {
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('CompetitorOptions')
      .subscribe(
        (ajaxData) => {
          this.loadCompetitors(ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods (helper functions)
 */
  private loadActionRecords(loadEvent, ajaxData) {
console.log('loadActionRecords',ajaxData); // PIG's Debug 27/09/2019 17:02:57
    this.totalActionRecords = ajaxData.total;
    let totalTriggers = 0;
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = TaskFactory.create(ajaxRow);
      this.actionRecords.push(record);
      /* a bit of a cosmetic cludge as the row height is variable based on the number of triggers */
      totalTriggers += record.priceTriggers.length
    });
    this.actionsPanelMeta.virtualRowHeight = Math.max(37,20 * totalTriggers / this.actionRecords.length);
console.log('totalTriggers',totalTriggers,this.actionRecords.length,this.actionsPanelMeta.virtualRowHeight); // PIG's Debug 13/11/2019 14:31:38
    this.loading = false;
  }

  private loadAdwordRecords(loadEvent, ajaxData) {
console.log('loadAdwordRecords',ajaxData); // PIG's Debug 27/09/2019 17:02:57
    this.totalAdwordRecords = ajaxData.total;
    let totalTriggers = 0;
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = TaskFactory.create(ajaxRow);
      this.adwordRecords.push(record);
      /* a bit of a cosmetic cludge as the row height is variable based on the number of triggers */
      totalTriggers += record.priceTriggers.length
    });
    this.adwordsPanelMeta.virtualRowHeight = Math.max(37,20 * totalTriggers / this.adwordRecords.length);
console.log('totalTriggers',totalTriggers,this.adwordRecords.length,this.adwordsPanelMeta.virtualRowHeight); // PIG's Debug 13/11/2019 14:31:38
    this.loading = false;
  }

  private loadReviewRecords(loadEvent, ajaxData) {
    this.totalReviewRecords = ajaxData.total;
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = TaskFactory.create(ajaxRow);
      this.reviewRecords.push(record);
    });

    this.loading = false;
  }

  private loadStockRecords(ajaxData) {
    const stockRecords = [];
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = StockFactory.create(ajaxRow);
      stockRecords.push(record);
    });
    return stockRecords;
  }

  private loadSupplierOptions(ajaxData) {
    ajaxData.dataset.forEach((ajaxRow) => {
      this.suppliers.push({label: ajaxRow.sup_name, value: ajaxRow.sup_id});
    });

    this.loading = false;
  }

  private loadCompetitors(ajaxData) {
    ajaxData.dataset.forEach((ajaxRow) => {
      this.competitors[ajaxRow.cmp_id] = ajaxRow.cmp_name;
    });

    this.loading = false;
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }


  localTypeof(thing) { return typeof thing; }

}
