/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';
import { DOCUMENT } from "@angular/common";
import { Subject, Subscription, forkJoin } from 'rxjs';
import { switchMap, tap, takeUntil, first } from 'rxjs/operators';

/**
 * Import 3rd party modules
 */
import { ResizeEvent } from 'angular-resizable-element';
import { UIChart } from 'primeng/components/chart/chart';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from '../general-services/browser-window.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { AjaxData, AjaxDataService } from '../ajax-services/ajax-data.service';
import { DialogService } from '../general-services/dialog.service';
import { RegionDescriptions, RegionCurrencies, WeightingsRecords, CompetitorSettingsColumns, WeightingsColumns } from "../data-definitions/global-constants"
import { Variation, VariationFactory } from '../data-definitions/variation';
import { Product, ProductFactory } from '../data-definitions/product';

/**
 * Define the component
 */
@Component({
  selector: 'app-edit-variation',
  templateUrl: './edit-variation.component.html',
  styleUrls: ['./edit-variation.component.css']
})
export class EditVariationComponent implements OnInit {

/**
 * Public properties
 */
  public math = Math; // make math available in the template
  public region = "Mars";
  public regionDescriptions = RegionDescriptions;
  public regionCurrencies = RegionCurrencies;
  public variationId;
  public variation: Variation;
  public product: Product;
  public competitorRecords = [];
  public weightingsRecords = WeightingsRecords;
  public totalCompetitorRecords = 0;
  public competitorsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 34, rows: 10, columns: CompetitorSettingsColumns };
  public weightingsPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '500px', virtualRowHeight: 34, rows: 10, columns: WeightingsColumns };
  public loading = true;

  public locked = {weights: true,
                   weights_region: true,
                   base: true,
                   brand: true,
                   brand_region: true,
                   product: true,
                   product_region: false};

/**
 * Private properties
 */
  private windowRef: Window;
  private onDestroy$ = new Subject();

/**
 * External linkage
 */
  @ViewChild('weightingsForm', { static: true }) public weightingsForm: NgForm;

/**
 * The constructor and init/destroy methods
 */
  public constructor(private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private browserWindowService: BrowserWindowService,
                     private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
   @Inject(DOCUMENT) private documentRef: Document) {

  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url;
    this.variationId = this.activatedRoute.snapshot.paramMap.get('id');

    /** Create an observable to return record details */
    let recordDetails$;
    recordDetails$ = this.activatedRoute.paramMap.pipe(
        switchMap((paramMap: ParamMap) => {
          const url = this.activatedRoute.snapshot.url;
          this.variationId = Number(paramMap.get('id'));
          this.region = paramMap.get('region');
          let regionColumnMeta = this.weightingsPanelMeta.columns.find(meta => meta.field === 'product_region');
          regionColumnMeta.header = regionColumnMeta.header.replace('XX',RegionDescriptions[this.region]);
          regionColumnMeta = this.weightingsPanelMeta.columns.find(meta => meta.field === 'brand_region');
          regionColumnMeta.header = regionColumnMeta.header.replace('XX',RegionDescriptions[this.region]);
          regionColumnMeta = this.weightingsPanelMeta.columns.find(meta => meta.field === 'weights_region');
          regionColumnMeta.header = regionColumnMeta.header.replace('XX',RegionDescriptions[this.region]);
          return forkJoin(
            this.ajaxDataService.getAjaxDetails('VariationDetails', this.variationId, this.region),
            this.ajaxDataService.getAjaxDetails('ProductDetails', this.variationId, this.region),
            this.ajaxDataService.getAjaxDetails('WeightingDetails', this.variationId, this.region),
          );
      })
    );
/** subscribe to the combined observables and update the form(s) on route change */
    recordDetails$
      .subscribe(
        (ajaxData: Array<any>) => {
console.log('ngOnInit ajaxData',ajaxData); // PIG's Debug 27/09/2019 11:47:46
          if (ajaxData[0].total) {
            this.variation = VariationFactory.create(ajaxData[0].dataset[0]);
          } else {
            this.handleError(new Error('No data record was returned for this variation'));
          }
          if (ajaxData[1].total) {
            this.product = ProductFactory.create(ajaxData[1].dataset[0]);
          } else {
            this.handleError(new Error('No data record was returned for parent product'));
          }
          if (ajaxData[2].total) {
            this.loadWeightingData(ajaxData[2]);
          } else {
            this.handleError(new Error('No weighting data record was returned'));
          }
        },
        error => {
          this.handleError(error);
        }
      );
  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */

  public onUpdateCompetitors(event: any,form: NgForm) {
console.log('onUpdateWeightings',event,form); // PIG's Debug 10/07/2019 11:29:00
    const formData: FormData = this.ajaxDataService.ajaxFormDataFromGroup(form.form,true);
    formData.append('pva_id', this.variationId);
    formData.append('region', this.region);
    this.ajaxDataService.postAjaxDetails('UpdateCompetitors',formData)
      .subscribe(
        (ajaxResponse: AjaxData) => {
alert("Sent");
        },
        error => {
          this.handleError(error);
        });
  }

  public onUpdateWeightings(event: any,form: NgForm) {
console.log('onUpdateWeightings',event,form); // PIG's Debug 10/07/2019 11:29:00
    const formData: FormData = this.ajaxDataService.ajaxFormDataFromGroup(form.form,true);
    formData.append('pva_id', this.variationId);
    formData.append('region', this.region);
    this.ajaxDataService.postAjaxDetails('UpdateWeighting',formData,this.variationId)
      .subscribe(
        (ajaxResponse: AjaxData) => {
alert("Sent");
        },
        error => {
          this.handleError(error);
        });
  }

  public onToggleLock(event: any,form: NgForm,column: string) {
console.log('onToggleLock',event,form,column); // PIG's Debug 10/07/2019 11:29:00
    Object.keys(form.form.controls).forEach(formKey => {
      if (formKey.endsWith('_' + column))
      {
        const formControl = form.form.get(formKey);
console.log('onToggleLock',formControl); // PIG's Debug 06/11/2019 14:56:23
        if (formControl.disabled) {
          formControl.enable();
        } else {
          formControl.disable();
        }
      }
    });
    this.locked[column] = !this.locked[column];
  }

  public onClickErase(event: any,form: NgForm,column: string) {
console.log('onClickErase',event,form,column); // PIG's Debug 10/07/2019 11:29:00
    if (this.locked[column]) {
      return false;
    }
    Object.keys(form.form.controls).forEach(formKey => {
      if (formKey.endsWith('_' + column))
      {
        const formControl = form.form.get(formKey);
        formControl.setValue(null);
        formControl.markAsDirty();
      }
    });
  }

  public onResizeEnd(event: ResizeEvent, panel: any, dragContainer: any) {
console.log('panel',panel); // PIG's Debug 10/07/2019 11:29:00
  }

/**
 * Public methods called from the template
 */
  public fetchCompetitorResults(loadEvent) {
console.log('fetchCompetitorResults'); // PIG's Debug 31/10/2019 16:32:54
    if ((this.competitorRecords.length >= (loadEvent.first + loadEvent.rows)) ||  // we already have the requested data
        (this.totalCompetitorRecords && this.competitorRecords.length === this.totalCompetitorRecords)) {
        return;
    }

    const formData = new FormData();
    formData.append('pva_id', this.variationId);
    formData.append('region', this.region);
    this.loading = true;
    this.ajaxDataService.getAjaxSearch('VariationCompetitors',
                                       this.competitorRecords.length,
                                       (loadEvent.first + loadEvent.rows - this.competitorRecords.length),
                                       null,
                                       null,
                                       formData)
      .subscribe(
        (ajaxData) => {
          this.loadCompetitorRecords(loadEvent, ajaxData);
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods
 */

/**
 * Private methods (helper functions)
 */
  private loadCompetitorRecords(loadEvent, ajaxData) {
console.log('loadCompetitorRecords',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    this.totalCompetitorRecords = ajaxData.total;
    ajaxData.dataset.forEach((ajaxRow) => {
      // const record = TaskFactory.create(ajaxRow);
      const record = {id: ajaxRow.cmp_id,name: ajaxRow.cmp_name,base: ajaxRow.prh_base,price: ajaxRow.prh_price,region: ajaxRow.prh_region};
      this.competitorRecords.push(record);
    });

    this.loading = false;
  }

  private loadWeightingData(ajaxData) {
console.log('loadWeightingData',ajaxData); // PIG's Debug 24/09/2019 17:17:13
    this.totalCompetitorRecords = ajaxData.total;
    // reset the fields
    ["weights","base","brand","product","region"].forEach(column => {
      this.weightingsRecords.forEach(record => {
        record[column] = null;
        if (record.hasThreshold) {
          record[column + "_threshold"] = null;
        }
      });
    })
    ajaxData.dataset.forEach((ajaxRow) => {
      let column = "";
      if (ajaxRow.pvw_region && ajaxRow.pvw_weights) {
        column = "weights_region"
      }
      else if (ajaxRow.pvw_weights) {
        column = "weights"
      }
      else if (ajaxRow.pvw_region && ajaxRow.pvw_pva_id) {
        column = "product_region"
      }
      else if (ajaxRow.pvw_pva_id) {
        column = "product"
      }
      else if (ajaxRow.pvw_region && ajaxRow.pvw_sup_id) {
        column = "brand_region"
      }
      else if (ajaxRow.pvw_sup_id) {
        column = "brand"
      }
      else {
        column = "base"
      }
      this.weightingsRecords.forEach(record => {
        record[column] = ajaxRow[record.field];
        if (record.hasThreshold) {
          record[column + "_threshold"] = ajaxRow[record.field  + "_threshold"];
        }
      });


console.log('this.weightingsRecords',this.weightingsRecords); // PIG's Debug 13/11/2019 15:06:18
    });

    this.loading = false;
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }

}


