export enum StateDescriptions {
    active = "Active triggers",
    snoozed = "Snoozed triggers",
    all = "All products"
   }

export enum TypeDescriptions {
    adword = "Adword triggers",
    price = "Price triggers",
   }

export enum RegionDescriptions {
    gb = "UK",
    de = "DE",
    dk = "DK",
    es = "ES",
    fr = "FR",
    it = "IT",
    nl = "NL",
    se = "SE",
    au = "AU",
    us = "US"
   }

export enum RegionCurrencies {
    gb = "GBP",
    de = "EUR",
    dk = "DKK",
    es = "EUR",
    fr = "EUR",
    it = "EUR",
    nl = "EUR",
    se = "SEK",
    au = "AUD",
    us = "USD"
   }

export const lineColours = ['#e6194b','#3cb44b','#ffe119','#4363d8','#f58231','#911eb4','#46f0f0','#f032e6','#bcf60c','#fabebe','#008080','#e6beff','#9a6324','#fffac8','#800000','#aaffc3','#808000','#ffd8b1','#000075','#808080','#ffffff','#000000'];

export const actionsColumns = [
  { field: 'name', header: 'Product', sortable: true, sortOrder: null, style: {width: "27%","text-align": "left"} },
  { field: 'region', header: 'Site', sortable: false, sortOrder: null, style: {width: "3%","text-align": "left"} },
  { field: 'impact', header: 'Impact', sortable: true, sortOrder: -1, style: {width: "7%","text-align": "left"} },
  { field: 'price', header: 'CB Price', sortable: false, sortOrder: null, style: {width: "5%","text-align": "left"} },
//  { field: 'date', header: 'Date', sortable: true, sortOrder: null, style: {width: "10%","text-align": "left"} },
  { field: 'triggers', header: 'What', sortable: false, sortOrder: null, style: {width: "38%","text-align": "left"} },
  { field: 'action', header: 'Action', sortable: false, sortOrder: null, style: {width: "22%","text-align": "left"} },
];

export const adwordsColumns = [
  { field: 'name', header: 'Product', sortable: false, sortOrder: 1, style: {width: "25%","text-align": "left"} },
//  { field: 'region', header: 'Site', sortable: false, sortOrder: null, style: {width: "5%","text-align": "left"} },
  { field: 'triggers', header: 'Adwords', sortable: false, sortOrder: null, style: {width: "60%","text-align": "left"} },
  { field: 'action', header: 'Action', sortable: false, sortOrder: null, style: {width: "10%","text-align": "left"} },
];

export const reviewsColumns = [
  { field: 'name', header: 'Product', sortable: false, sortOrder: 1, style: {width: "25%","text-align": "left"} },
  { field: 'region', header: 'Site', sortable: false, sortOrder: null, style: {width: "5%","text-align": "left"} },
  { field: 'triggers', header: 'Matched', sortable: false, sortOrder: null, style: {width: "60%","text-align": "left"} },
  { field: 'action', header: 'Action', sortable: false, sortOrder: null, style: {width: "10%","text-align": "left"} },
];

export const competitorsColumns = [
  { field: 'name', header: 'Seller', sortable: true, sortOrder: null, style: {width: "15%","text-align": "left"} },
  { field: 'price', header: 'Price', sortable: true, sortOrder: 1, style: {width: "15%","text-align": "left"} },
  { field: 'date', header: 'Since', sortable: true, sortOrder: null, style: {width: "15%","text-align": "left"} },
  { field: 'misc', header: 'Misc.', sortable: false, sortOrder: null, style: {width: "45%","text-align": "left"} },
//  { field: 'triggers', header: 'History', sortable: false, sortOrder: null, style: {width: "50%","text-align": "left"} },
];

export const notesColumns = [
  { field: 'date', header: 'Date', sortable: true, sortOrder: null, style: {width: "10%","text-align": "left"} },
  { field: 'userName', header: 'User', sortable: true, sortOrder: 1, style: {width: "15%","text-align": "left"} },
  { field: 'note', header: 'Note', sortable: true, sortOrder: null, style: {width: "75%","text-align": "left"} },
];

export const CompetitorSettingsColumns = [
  { field: 'name', header: 'Seller', sortable: false, sortOrder: null, style: {width: "30%","text-align": "left"} },
  { field: 'price', header: 'Current Price', sortable: false, sortOrder: null, style: {width: "10%","text-align": "left"} },
  { field: 'base', header: 'RRP (Base)', sortable: false, sortOrder: null, style: {width: "60%","text-align": "left"} },
];

export const WeightingsColumns /* on variation edit */ = [
  { field: 'name', header: 'Feature', sortable: false, sortOrder: null, style: {width: "26%","text-align": "left"}, hasThreshold: true, hasLock: false, hasErase: false },
  { field: 'weights', header: 'Weights', sortable: false, sortOrder: null, style: {width: "7%","text-align": "left"}, hasThreshold: false, hasLock: true, hasErase: false },
  { field: 'weights_region', header: 'Weights (XX)', sortable: false, sortOrder: null, style: {width: "7%","text-align": "left"}, hasThreshold: false, hasLock: true, hasErase: false },
  { field: 'base', header: 'Base (fallback)', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: true, hasErase: false },
  { field: 'brand', header: 'Brand', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: true, hasErase: false },
  { field: 'brand_region', header: 'Brand (XX)', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: true, hasErase: false },
  { field: 'product', header: 'Product', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: true, hasErase: true },
  { field: 'product_region', header: 'Product (XX)', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: false, hasErase: true },
/*
  { field: 'threshold', header: 'Threshold', sortable: false, sortOrder: null, style: {width: "15%","text-align": "left"} },
*/
];

export const WeightingsColumns2 /* on weightings edit*/ = [
  { field: 'name', header: 'Feature', sortable: false, sortOrder: null, style: {width: "44%","text-align": "left"}, hasThreshold: true, hasLock: false, hasErase: false },
  { field: 'weights', header: 'Weights', sortable: false, sortOrder: null, style: {width: "8%","text-align": "left"}, hasThreshold: false, hasLock: false, hasErase: false },
  { field: 'base', header: 'Base', sortable: false, sortOrder: null, style: {width: "12%","text-align": "left"}, hasThreshold: true, hasLock: false, hasErase: false },
/*
  { field: 'threshold', header: 'Threshold', sortable: false, sortOrder: null, style: {width: "15%","text-align": "left"} },
*/
];

export const BrandSettingsColumns = [
  { field: 'name', header: 'Brand', sortable: false, sortOrder: null, style: {width: "60%","text-align": "left"} },
  { field: 'pvw_brand_importance', header: 'Brand importance (Price)', sortable: false, sortOrder: null, style: {width: "20%","text-align": "left"} },
  { field: 'pvw_brand_importance_ads', header: 'Brand importance (Ads)', sortable: false, sortOrder: null, style: {width: "20%","text-align": "left"} },
];

export const WeightingsRecords = [
  { field: 'pvw_manual_override', id: 1, value: 45, name: 'Manual Override', hasThreshold: false, threshold: null },
  { field: 'pvw_brand_importance', id: 2, value: 45, name: 'Brand importance', hasThreshold: false, threshold: null },
  { field: 'pvw_new_product', id: 3, value: 45, name: 'New Product/Brand (1-3M)', hasThreshold: false, threshold: null },
  { field: 'pvw_recent_product', id: 14, value: 45, name: 'New Product/Brand (4-6M)', hasThreshold: false, threshold: null },
  { field: 'pvw_monthly_revenue', id: 4, value: 45, name: 'Monthly revenue weighting', hasThreshold: true, threshold: null },
  { field: 'pvw_competitor_increases', id: 5, value: 45, name: 'Currently matching, Competitor increases', hasThreshold: false, threshold: null },
  { field: 'pvw_competitor_decreases', id: 6, value: 45, name: 'Competitor decreases', hasThreshold: false, threshold: null },
  { field: 'pvw_competitor_undercuts', id: 7, value: 45, name: 'Competitor undercuts', hasThreshold: false, threshold: null },
  { field: 'pvw_out_of_stock', id: 9, value: 45, name: 'Goes out of stock', hasThreshold: false, threshold: null },
  { field: 'pvw_restocked', id: 10, value: 45, name: 'Comes back in stock', hasThreshold: false, threshold: null },
  { field: 'pvw_stock_exceeds', id: 11, value: 45, name: 'Stock exceeds threshold', hasThreshold: true, threshold: null },
  { field: 'pvw_stock_fails', id: 12, value: 45, name: 'Stock fails threshold', hasThreshold: true, threshold: null },
  { field: 'pvw_advert_position', id: 13, value: 45, name: 'Negative change in ad. position', hasThreshold: false, threshold: null },
  { field: 'pvw_traffic_level', id: 8, value: 45, name: 'Traffic level passes threshold', hasThreshold: true, threshold: null },
];



export const priceChartOptions = {
  title: {display: false,},
  legend: {display: false,},
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 300,
    easing: 'linear'
  },
  scales: {
    yAxes: [{
      id: 'line',
      type: 'linear',
      position: 'left',
/*
      ticks: {
        beginAtZero: true,
      }
*/
    }],
    xAxes: [{
      display: false,
      gridLines: {display: false,},
    }]
  }
}

export const stockChartOptions = {
  title: {display: false,},
  legend: {display: false,},
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 300,
    easing: 'linear'
  },
  scales: {
    yAxes: [{
      id: 'line',
      type: 'linear',
      position: 'left',
      ticks: {
        beginAtZero: true,
      }
    }],
    xAxes: [{
      gridLines: {display: false,},
      scaleLabel: {
        display: true,
        labelString: 'Weeks'
      },
    }]
  }
}

export const adwordsChartOptions = {
  title: {display: false,},
  legend: {display: false,},
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 300,
    easing: 'linear'
  },
  scales: {
    yAxes: [{
      id: 'Clicks',
      type: 'linear',
      position: 'left',
      ticks: {
        beginAtZero: true,
      }
    }, {
      id: 'Share',
      type: 'linear',
      position: 'right',
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 10000,
        callback: value => {
          return value;
        }
      }
    }],
    xAxes: [{
      gridLines: {display: false,},
      scaleLabel: {
        display: true,
        labelString: 'Weeks'
      },
    }]
  }
}

export const textadsChartOptions = {
  title: {display: false,},
  legend: {display: false,},
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 300,
    easing: 'linear'
  },
  scales: {
    yAxes: [{
      id: 'Sessions',
      type: 'linear',
      position: 'left',
      ticks: {
        beginAtZero: true,
      }
/*
    }, {
      id: 'Sessions2',
      type: 'linear',
      position: 'left',
      ticks: {
        beginAtZero: true,
      }
*/
    }, {
      id: 'Revenue',
      type: 'linear',
      position: 'right',
      ticks: {
        beginAtZero: true,
        min: 0,
        callback: value => {
          return value;
        }
      }
    }],
    xAxes: [{
      gridLines: {display: false,},
      scaleLabel: {
        display: true,
        labelString: 'Weeks'
      },
    }]
  }
}

export const salesChartOptions = {
  title: {display: false,},
  legend: {display: false,},
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 300,
    easing: 'linear'
  },
  scales: {
    yAxes: [{
      id: 'Sales',
      type: 'linear',
      position: 'left',
      gridLines: {display: false,},
      ticks: {
/*
        beginAtZero: true,
*/
        callback: value => {
          if (value % 1 === 0) {return value;} // only render integer values (when very low sales values)
        }
      }
    }, {
      id: 'Price',
      type: 'linear',
      position: 'right',
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 1000,
        callback: value => {
          return value;
        }
      }
    }],
    xAxes: [{
      gridLines: {display: false,},
      scaleLabel: {
        display: true,
        labelString: 'Weeks'
      },
    }]
  }
}

