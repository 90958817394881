/**
 * Import framework modules
 */
import { Component, ComponentRef, OnInit, HostListener, ViewContainerRef, Renderer2 } from '@angular/core';
import { Observable, Subject ,  Subscription } from 'rxjs';

/**
 * Import 3rd party modules
 */
import { IModalDialog,
  			 IModalDialogOptions,
  			 IModalDialogButton,
  			 IModalDialogSettings, ModalDialogOnAction } from 'ngx-modal-dialog';

/**
 * Import app specific elements
 */
import { AjaxDataService, AjaxData } from '../../ajax-services/ajax-data.service';
import { DialogService } from '../../general-services/dialog.service';
import { AuthorisationService } from '../../general-services/authorisation.service';

/**
 * Define the component
 */
@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements IModalDialog {

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
console.log("esc",event);
    if (event.keyCode === 27) {
      this.completionSubject.next({} as AjaxData);
      this.closeDialogSubject.next();
    }
  }

/**
 * Public properties
 */
  public ajaxActive = false;
  public channels = [];
  public selectedChannel = null;
  public users = [];
  public selectedUser = null;
  public referralNote = null;
  public systemNote = null;
  public loading = true;

/**
 * Private properties
 */
  private completionSubject: Subject<AjaxData>; // returns data from the dialog when it completes
  private closeDialogSubject: Subject<void>; // triggered (e.g. by user hitting escape key) to signal parent to close this dialog
  private activeSubscriptions = new Subscription(); // hold an array of subscriptions to cancel on destroy

  private priceTriggerIds: [];
  private stockTriggerIds: [];
  private variationIds: [];
  private actionButtons: IModalDialogButton[];

/**
 * The constructor and init/destroy methods
 */
  public constructor(private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
                     private authorisationService: AuthorisationService) {
    this.actionButtons = [
      { text: 'Cancel', onAction: () => {
        if (!this.ajaxActive) {
          this.completionSubject.next({} as AjaxData);
          return true;
        }
      }},
      { text: 'Send', onAction: () => {
				if (!this.selectedChannel && !this.selectedUser) {
					alert ("No recipient chosen");
				} else if (!this.referralNote) {
					alert ("No referral text to send");
				} else {
	        this.submit();
				}
      }},
      { text: 'Internal Note Only', onAction: () => {
				if (!this.referralNote && !this.systemNote) {
					alert ("No note text to save");
				} else {
	        this.submit(true);
	      }
      }}
    ];
  }

  public ngOnInit() {
    this.fetchChannels();
    this.fetchUsers();
  }

  public ngOnDestroy() {
    this.activeSubscriptions.unsubscribe();
  }

/**
 * Public methods
 */
  public dialogInit(reference: ComponentRef<IModalDialog>, options?: Partial<IModalDialogOptions<any>>) {
	  this.variationIds = options.data.variationIds;
	  this.priceTriggerIds = options.data.priceTriggerIds;
	  this.stockTriggerIds = options.data.stockTriggerIds;
	  this.completionSubject = options.data.completionSubject;
    this.closeDialogSubject = options.closeDialogSubject;

    /* subscribe to the logged in status so that if it changes to logged out the dialog will be cancelled and closed */
    this.activeSubscriptions.add(this.authorisationService.isLoggedIn
      .subscribe((isLoggedIn: boolean) => {
console.log("isLoggedInSubscription",isLoggedIn)
        if (!isLoggedIn) {
          this.completionSubject.next({} as AjaxData);
          this.closeDialogSubject.next();
        }
      })
    );
  }

/**
 * Private methods
 */
  private fetchChannels() {
    this.loading = false; // true;
    this.ajaxDataService.getAjaxSearch('ChannelOptions')
      .subscribe(
        (ajaxData) => {
          this.loadOptions(ajaxData,this.channels);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private fetchUsers() {
    this.loading = false; // true;
    this.ajaxDataService.getAjaxSearch('UserOptions')
      .subscribe(
        (ajaxData) => {
          this.loadOptions(ajaxData,this.users);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private loadOptions(ajaxData,list) {
    ajaxData.dataset.forEach((ajaxRow) => {
      list.push({label: ajaxRow.slk_name, value: ajaxRow.slk_id});
    });

    this.loading = false;
  }

  private submit(internal: boolean = false) {
console.log('submit',internal,this.selectedChannel,this.selectedUser,this.referralNote); // PIG's Debug 30/09/2019 14:13:47
    if (this.ajaxActive) {
      return;
    }
    this.ajaxActive = true;
    const formData: FormData = new FormData;
    if (this.selectedChannel && !internal) { formData.append('slk_id', this.selectedChannel); }
    if (this.selectedUser && !internal) { formData.append('slk_id', this.selectedUser); }
    if (this.referralNote) { formData.append('referral_note', this.referralNote); }
    if (this.systemNote) { formData.append('system_note', this.systemNote); }
    this.priceTriggerIds.forEach(triggerId => {
      formData.append("prh_id[]", triggerId);
    });
    this.variationIds.forEach(variationId => {
      formData.append("pva_id[]", variationId);
    });
    this.ajaxDataService.postAjaxDetails('ReferAction',formData)
      .subscribe(
        (ajaxResponse: AjaxData) => {
console.log("on	",ajaxResponse);
          this.completionSubject.next(ajaxResponse);
          this.closeDialogSubject.next();
        },
        error => {
          this.handleError(error);
        });
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    this.completionSubject.next({} as AjaxData);
    this.closeDialogSubject.next();
    return this.dialogService.reportError(error);
  }

}
