import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // preferred for AngularMultiSelectModule

import { ResizableModule } from 'angular-resizable-element';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Oauth2Component } from './oauth2/oauth2.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthoriseComponent } from './authorise/authorise.component';
import { httpInterceptorProviders } from './ajax-services/http-interceptor-providers';
import { VariationComponent } from './variation/variation.component';
import { RemindComponent } from './pop-up-components/remind/remind.component';
import { ReferComponent } from './pop-up-components/refer/refer.component';
import { NotesComponent } from './pop-up-components/notes/notes.component';
import { HistoryGraphComponent } from './history-graph/history-graph.component';
import { EditVariationComponent } from './edit-variation/edit-variation.component';
import { WeightingsComponent } from './weightings/weightings.component';

@NgModule({
  declarations: [
    AppComponent,
    Oauth2Component,
    LoginComponent,
    MainComponent,
    NotFoundComponent,
    DashboardComponent,
    AuthoriseComponent,
    VariationComponent,
    RemindComponent,
    ReferComponent,
    NotesComponent,
    HistoryGraphComponent,
    EditVariationComponent,
    WeightingsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ResizableModule,
    TableModule,
    ChartModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    TooltipModule,
    CheckboxModule,
    ModalDialogModule.forRoot(),
  ],
  providers: [
    httpInterceptorProviders
  ],
  entryComponents: [
    RemindComponent,
    ReferComponent,
    NotesComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
