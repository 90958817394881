/**
 * Import framework modules
 */
import { Component, ComponentRef, OnInit, HostListener, ViewContainerRef, Renderer2 } from '@angular/core';
import { Observable, Subject ,  Subscription } from 'rxjs';


/**
 * Import 3rd party modules
 */
import { IModalDialog,
  			 IModalDialogOptions,
  			 IModalDialogButton,
  			 IModalDialogSettings, ModalDialogOnAction } from 'ngx-modal-dialog';

/**
 * Import app specific elements
 */
import { AjaxDataService, AjaxData } from '../../ajax-services/ajax-data.service';
import { DialogService } from '../../general-services/dialog.service';
import { AuthorisationService } from '../../general-services/authorisation.service';
import { Note, NoteFactory } from '../../data-definitions/note';
import { notesColumns } from "../../data-definitions/global-constants"

/**
 * Define the component
 */
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements IModalDialog {

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
console.log("esc",event);
    if (event.keyCode === 27) {
      this.completionSubject.next({} as AjaxData);
      this.closeDialogSubject.next();
    }
  }

/**
 * Public properties
 */
  public noteRecords = [];
  public totalNoteRecords = 0;
  public loading = true;
  public notesPanelMeta = { title: 'Placebo', style: {}, scrollHeight: '300px', virtualRowHeight: 34, rows: 10, columns: notesColumns };


/**
 * Private properties
 */
  private completionSubject: Subject<AjaxData>; // returns data from the dialog when it completes
  private closeDialogSubject: Subject<void>; // triggered (e.g. by user hitting escape key) to signal parent to close this dialog
  private activeSubscriptions = new Subscription(); // hold an array of subscriptions to cancel on destroy

  private variationId: string;
  private actionButtons: IModalDialogButton[];

/**
 * The constructor and init/destroy methods
 */
  public constructor(private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
                     private authorisationService: AuthorisationService) {
    this.actionButtons = [
      { text: 'Cancel', onAction: () => {
        this.completionSubject.next({} as AjaxData);
        return true;
        }
      }
    ];
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.activeSubscriptions.unsubscribe();
  }

/**
 * Public methods
 */
  public dialogInit(reference: ComponentRef<IModalDialog>, options?: Partial<IModalDialogOptions<any>>) {
	  this.variationId = options.data.variationId;
	  this.completionSubject = options.data.completionSubject;
    this.closeDialogSubject = options.closeDialogSubject;

    /* subscribe to the logged in status so that if it changes to logged out the dialog will be cancelled and closed */
    this.activeSubscriptions.add(this.authorisationService.isLoggedIn
      .subscribe((isLoggedIn: boolean) => {
console.log("isLoggedInSubscription",isLoggedIn)
        if (!isLoggedIn) {
          this.completionSubject.next({} as AjaxData);
          this.closeDialogSubject.next();
        }
      })
    );
    this.fetchNotes();
  }

/**
 * Private methods
 */
  private fetchNotes() {
console.log('fetchNotes',this.variationId); // PIG's Debug 05/11/2019 15:04:58
    const formData: FormData = new FormData;
      formData.append("pva_id", this.variationId);
    this.ajaxDataService.postAjaxDetails('GetNotes',formData)
      .subscribe(
        (ajaxData: AjaxData) => {
console.log('fetchNotes ajaxData',ajaxData); // PIG's Debug 05/11/2019 14:52:40
          this.loadNoteRecords(ajaxData);
        },
        error => {
          this.handleError(error);
        });
  }

  private loadNoteRecords(ajaxData) {
    this.totalNoteRecords = ajaxData.total;
    ajaxData.dataset.forEach((ajaxRow) => {
      const record = NoteFactory.create(ajaxRow);
      this.noteRecords.push(record);
console.log('loadNoteRecords',ajaxRow,record); // PIG's Debug 01/11/2019 11:37:30
    });
    this.loading = false;
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }

}
