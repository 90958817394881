import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { catchError, finalize, tap, share } from 'rxjs/operators';

import { LocalUser } from '../data-definitions/local-user';
import { AjaxDataService } from '../ajax-services/ajax-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  public get isLoggedIn() {
    return this.loggedIn.asObservable().pipe(share());
  }

  public constructor(private router: Router,
                     private ajaxDataService: AjaxDataService) {
    let localUser = JSON.parse(localStorage.getItem('localUser'));
    if (localUser) {
      this.loggedIn.next(true);
    }
  }

  login(username: string,password: string): Observable<any> {
    return this.fetchLoginToken(username,password)
  }

  logout() {
		localStorage.removeItem('localUser');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  reset(username: string): Observable<any> {
    return this.sendLoginReset(username)
  }

/**
 * Fetch user data as a JSON array from the backend
 *
 * Currently this is only actioned when the user is actually logged out, the
 * token is continually refreshed via an HTTP header at a frequency controlled
 * by the back end.
 */

  private fetchLoginToken(username: string,password: string,reset?: string) {
    return this.ajaxDataService.getAjaxLogin(username,password)
      .pipe(
        tap((ajaxData: any) => {
console.log("ajaxData",ajaxData);
          if (ajaxData && ajaxData.localUser.token) {
            localStorage.setItem('localUser',JSON.stringify(ajaxData.localUser));
            this.loggedIn.next(true);
          }
        })
      );
  }

  private sendLoginReset(username: string) {
    return this.ajaxDataService.sendAjaxReset(username)
      .pipe(
        tap((ajaxData: any) => {
console.log("ajaxData",ajaxData);
        })
      );
  }

}
