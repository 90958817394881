/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Stock {

  public id:                number;
  public region:            string;
  public name:              string;
  public code:              string;
  public stock:             number;
  public week1:             number;
  public week2:             number;
  public week3:             number;
  public week4:             number;
  public days28:            number;
  public days90:            number;

  constructor(parameters: {
			id?:                number,
			region?:            string,
			name?:              string,
			code?:              string,
  		stock?:             number,
  		week1?:             number,
  		week2?:             number,
  		week3?:             number,
  		week4?:             number,
  		days28?:            number,
  		days90?:            number,
    } = {}) {
	  this.id               = parameters.id || null;
	  this.region           = parameters.region || '';
	  this.name             = parameters.name || 'N/A';
	  this.code             = parameters.code || 'N/A';
	  this.stock            = parameters.stock || 0;
	  this.week1            = parameters.week1 || 0;
	  this.week2            = parameters.week2 || 0;
	  this.week3            = parameters.week3 || 0;
	  this.week4            = parameters.week4 || 0;
	  this.days28           = parameters.days28 || 0;
	  this.days90           = parameters.days90 || 0;
	}
}

export class StockFactory {
	static create(stk_record: AjaxStock) {

    return new Stock({
    	id:               stk_record.pva_id,
			region:           stk_record.region,
			name:     		    stk_record.prd_name,
			code:     		    stk_record.pva_code,
			stock:     	    	stk_record.pvs_available_stock,
			week1:        		stk_record.week1,
			week2:        		stk_record.week2,
			week3:        		stk_record.week3,
			week4:        		stk_record.week4,
			days28:        		stk_record.days28,
			days90:        		stk_record.days90,
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxStock {
  pva_id:              number;
  region:              string;
  prd_name:            string;
  pva_code:            string;
  pvs_available_stock: number;
  week1:               number;
  week2:               number;
  week3:               number;
  week4:               number;
  days28:              number;
  days90:              number;
}