/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Variation {

  public id:            number;
  public code:          string;
  public variation1:    string;
  public variation2:    string;
  public variation3:    string;
  public price:         number;
/* stock data pulled for development contemplation at this stage */
  public shopify:       number;
  public brightpearl:   number;
  public backorder:     number;
  public delivery:      number;
  public deliveryDate:  number;
  public weeklySales:   number;

  constructor(parameters: {
			id?:            number,
			name?:          string,
			code?:          string,
			variation1?:    string,
			variation2?:    string,
			variation3?:    string,
			price?:         number,
			shopify?:       number,
			brightpearl?:   number,
			backorder?:     number,
			delivery?:      number,
			deliveryDate?:  number,
			weeklySales?:   number,
    } = {}) {
	  this.id           = parameters.id || null;
	  this.code         = parameters.code || 'no SKU';
	  this.variation1   = parameters.variation1 || '';
	  this.variation2   = parameters.variation2 || '';
	  this.variation3   = parameters.variation3 || '';
	  this.price        = parameters.price || 0;
	  this.shopify      = parameters.shopify || 0;
	  this.brightpearl  = parameters.brightpearl || 0;
	  this.backorder    = parameters.backorder || 0;
	  this.delivery     = parameters.delivery || 0;
	  this.deliveryDate = parameters.deliveryDate || 0;
	  this.weeklySales  = parameters.weeklySales || 0;
	}

  get name() {
    const variations = [this.variation1,this.variation2,this.variation3];
    var filtered = variations.filter(element => element);
    return filtered.join(",");
  }
}

export class VariationFactory {
	static create(pva_record: AjaxVariation) {

    return new Variation({
    	id:           pva_record.pva_id,
			code:     		pva_record.pva_code,
			variation1:   pva_record.pva_variation1,
			variation2:   pva_record.pva_variation2,
			variation3:   pva_record.pva_variation3,
			price:     		pva_record.pva_price,
			shopify:     	pva_record.pvs_shopify_stock,
			brightpearl:  pva_record.pvs_available_stock,
			backorder:    pva_record.pvs_backorder_stock,
			delivery:     pva_record.pvs_supplier_quantity,
			deliveryDate: pva_record.pvs_supplier_date,
			weeklySales:  pva_record.weekly_sales
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxVariation {
  pva_id:                 number;
  pva_name:               string;
  pva_code:               string;
  pva_variation1:         string;
  pva_variation2:         string;
  pva_variation3:         string;
  pva_price:              number;
/* stock data pulled for development contemplation at this stage */
  pvs_available_stock:    number;
  pvs_shopify_stock:      number;
  pvs_backorder_stock:    number;
  pvs_supplier_quantity:  number;
  pvs_supplier_date:      number;
  weekly_sales:           number;
}

