/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { throwError, Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

/**
 * Import app specific elements
 */

/**
 * Define structures
 */
const loginParams = new HttpParams({
  fromObject: {
    format: 'json',
    username: 'Undefined',
    password: 'Undefined',
  }
});

const detailsParams = new HttpParams({
  fromObject: {
    format: 'json',
    action: 'Undefined',
    id: 'Undefined',
//    checksum: 'Missing',
  }
});

export interface AjaxData {
  total: number;
  dataset: Array<{}>;
  global: {};
}

/**
 * Define the component
 */
@Injectable({
  providedIn: 'root'
})
export class AjaxDataService {

/**
 * Public properties
 */
  public static staticSingleton: AjaxDataService;
  public recordIdsUrl;
  public searchUrl;
  public loginUrl;
  public  documentUrl;

/**
 * Private properties
 */
  private metaUrl;
  private optionsUrl;
  private valueUrl;
  private detailsUrl;
  private serializeUrl;

/**
 * The constructor and init methods
 */
  constructor(private httpClient: HttpClient) {
    AjaxDataService.staticSingleton = this;
    let apiHost;
    if (window.location.hostname.search('bandini') < 0 && window.location.hostname.search('sysexcel.local') < 0) {
      apiHost = '//' + 'api.' + window.location.hostname;
    } else {
      apiHost = '//' + 'api.currentbody.xanda.local'
    }

//apiHost = 'http://' + 'dev.xanda.sysexcel.local'

    this.metaUrl = apiHost + '/ajax_metadata?action=';
    this.optionsUrl = apiHost + '/ajax_options';
    this.valueUrl = apiHost + '/ajax_value';
    this.recordIdsUrl = apiHost + '/ajax_record_ids';
    this.detailsUrl = apiHost + '/ajax_dataset';
    this.searchUrl = apiHost + '/ajax_search';
    this.loginUrl = apiHost + '/ajax_login';
    this.serializeUrl = apiHost + '/ajax_serialize';
    this.documentUrl = apiHost + '/document_listener';
  }

/**
 * Public methods
 */
  public getAjaxLogin(username: string,password: string,reset?: string): Observable<any> {
    let params = loginParams.set('username', username);
    params = params.set('password',password);
    return this.httpClient
      .get(this.loginUrl, { params: params })
      .pipe(
	      tap((ajaxData: any) => {
          if (ajaxData && ajaxData.token) {
          }
        }),
        catchError(this.handleError)
      );
  }

  public getAjaxSearch(action: string, first?: number, count?: number, sort?: string, direction?: number, formData?: FormData): Observable<any> {
    let params = new HttpParams({fromObject: {action: action} });
    if (first) params = params.set('first',first.toString());
    if (count) params = params.set('count',count.toString());
    if (sort) params = params.set('sort',sort);
    if (direction) params = params.set('direction',direction.toString());
    return this.httpClient
      .post(this.detailsUrl, formData, { params: params })
      .pipe(
	      tap(() => { /* console.log('Data server call completed') */}),
        catchError(this.handleError)
      );
  }

  public getAjaxDetails(action: string, id?: number, region?: string): Observable<any> {
    let params = detailsParams.set('action',action);
    if (id) params = params.set('id',id.toString());
    if (region) params = params.set('region',region);
    return this.httpClient
      .get(this.detailsUrl, { params: params })
      .pipe(
	      tap(() => {}),
        catchError(this.handleError)
      );
  }

	public postAjaxDetails(action: string,formData: FormData,id?: number): Observable<any> {
    let params = detailsParams.set('action',action);
    if (id) {
      params = params.set('id',id.toString());
    }
	  return this.httpClient
      .post(this.detailsUrl, formData, { params: params })
      .pipe(
	      tap(() => {}),
        catchError(this.handleError)
      );
	}

  public sendAjaxReset(username: string): Observable<any> {
    let params = loginParams.set('username', username);
    params = params.set('reset','reset');
    return this.httpClient
      .get(this.loginUrl, { params: params })
      .pipe(
	      tap((ajaxData: any) => {
          if (ajaxData && ajaxData.token) {
          }
        }),
        catchError(this.handleError)
      );
  }

	public ajaxFormDataFromGroup(formGroup: FormGroup, onlyDirty: boolean = false): FormData {
    const formData: FormData = new FormData();
    Object.keys(formGroup.controls).forEach(formKey => {
      const formControl = formGroup.get(formKey);
      if (!onlyDirty || formControl.dirty) {
        if (formControl.value instanceof Date) {
          formData.append('fes_' + formKey, (formControl.value.getTime() / 1000).toString());
        } else if (typeof formControl.value === 'boolean') {
          formData.append('fes_' + formKey, (formControl.value) ? '1' : '');
        } else if (typeof formControl.value === 'undefined') {
          formData.append('fes_' + formKey, '');
        } else if (formControl.value === null) {
          formData.append('fes_' + formKey, '');
        } else {
          formData.append('fes_' + formKey, formControl.value);
        }
      }
    });
    return formData;
  }

/**
 * Private methods
 */
  private handleError(error: HttpErrorResponse) {
console.error("AjaxDataService handleError",error);
	  return throwError(error);
  }

}













