/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Note {

  public id:            number;
  public variationId:   number;
  public region:        string;
  public date:          number;
  public userName:      string;
  public referralNote:  string;
  public systemNote:    string;
  public triggerNote:   string;

  constructor(parameters: {
			id?:            number,
			variationId?:   number,
			region?:        string,
			date?:          number,
			userName?:      string,
			referralNote?:  string,
			systemNote?:    string,
			triggerNote?:   string,
    } = {}) {
	  this.id            = parameters.id || null;
	  this.variationId   = parameters.variationId || null;
	  this.date          = parameters.date || null;
	  this.region        = parameters.region || null;
	  this.userName      = parameters.userName || 'N/A';
	  this.referralNote  = parameters.referralNote || '';
	  this.systemNote    = parameters.systemNote || '';
	  this.triggerNote   = parameters.triggerNote || '';
	}

  get note() {
    return this.referralNote + "\n" + this.systemNote
  }

  get tooltip() {
    return this.referralNote + "\n" + this.systemNote + "\n\n" + this.triggerNote;
  }
}

export class NoteFactory {
	static create(pvn_record: AjaxNote) {
    return new Note({
    	id:           pvn_record.pvn_id,
    	variationId:  pvn_record.pvn_pva_id,
			region:       pvn_record.pvn_region,
			date:         pvn_record.pvn_datetime_created,
			userName:     pvn_record.psl_name,
			referralNote: pvn_record.pvn_referral_note,
			systemNote:   pvn_record.pvn_system_note,
			triggerNote:  pvn_record.pvn_trigger_note
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxNote {
  pvn_id:               number;
  pvn_pva_id:           number;
  pvn_region:           string;
  pvn_datetime_created: number;
  psl_name:             string;
  pvn_referral_note:    string;
  pvn_system_note:      string;
  pvn_trigger_note:     string;
}

