/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Data structure for front end app
 */
export class Product {

  public id:          number;
  public name:        string;
  public image:       string;
  public shopifyId:   number;
  public impact:      number; // TODO this may become a graded enum rather thyan a value
  public date:        number;
  public triggers:    Array<any>;

  constructor(parameters: {
			id?:        number,
			name?:      string,
			image?:     string,
			shopifyId?: number,
    } = {}) {
	  this.id         = parameters.id || null;
	  this.name       = parameters.name || 'N/A';
	  this.image      = parameters.image || null;
	  this.shopifyId  = parameters.shopifyId || null;
	}
}

export class ProductFactory {
	static create(tsk_record: AjaxProduct) {

    return new Product({
    	id:           tsk_record.prd_id,
			name:     		tsk_record.prd_name,
			image:     		tsk_record.prd_image_url,
			shopifyId:    tsk_record.spf_ext_id,
 	  });
  }
}

/**
 * Record structure from back end end
 */
export interface AjaxProduct {
  prd_id:        number;
  prd_name:      string;
  prd_image_url: string;
  spf_ext_id:    number;
}

