/**
 * Import framework modules
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Import app specific elements
 */
import { AjaxDataService } from '../ajax-services/ajax-data.service';
import { DialogService } from '../general-services/dialog.service';

/**
 * Define the component
 */
@Component({
  selector: 'app-oauth2',
  templateUrl: './oauth2.component.html',
  styleUrls: ['./oauth2.component.css']
})
export class Oauth2Component implements OnInit {

  public constructor(private activatedRoute: ActivatedRoute,
                     private dialogService: DialogService,
                     private ajaxDataService: AjaxDataService) {
  }

  ngOnInit() {

console.log("window",window);
console.log("queryParams",this.activatedRoute.snapshot);
    this.onSubmit();

  }

  private onSubmit() {
    const formData: FormData = new FormData();
    Object.keys(this.activatedRoute.snapshot.queryParams).forEach(paramKey => {
      formData.append(paramKey,this.activatedRoute.snapshot.queryParams[paramKey]);
    });
    this.ajaxDataService.postAjaxDetails('Oauth2',formData)
      .subscribe(
        ajaxResponse => {
window.opener.location.reload();
window.close();
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error("handleError",error);
    return this.dialogService.reportError(error);
  }

}
