/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Injectable, ViewContainerRef, ApplicationRef } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Import 3rd party modules
 */
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';

/**
 * Import app specific elements
 */
import { AppComponent } from '../app.component';
import { RemindComponent } from '../pop-up-components/remind/remind.component';
import { ReferComponent } from '../pop-up-components/refer/refer.component';
import { NotesComponent } from '../pop-up-components/notes/notes.component';
import { AjaxData } from '../ajax-services/ajax-data.service';

/**
 * Define the service
 */
@Injectable({
  providedIn: 'root'
})
export class ActionPopupService {

/**
 * Private properties
 */
  private rootViewContainerRef: ViewContainerRef;
  private completionSubject: Subject<AjaxData> = new Subject<AjaxData>();

  private remindDialogOptions = {
      title: 'Snooze this alert',
      childComponent: RemindComponent,
			data: {
     		completionSubject: this.completionSubject,
     		variationIds: [],
     		priceTriggerIds: [],
      },
      onClose: () => {
console.warn('Unexpected onClose'); // PIG's Debug 30/09/2019 13:46:54
        return false;
      },
      closeDialogSubject: new Subject<void>()
  };

  private referDialogOptions = {
      title: 'Refer this action',
      childComponent: ReferComponent,
			data: {
     		completionSubject: this.completionSubject,
     		variationIds: [],
     		priceTriggerIds: [],
      },
      onClose: () => {
console.warn('Unexpected onClose'); // PIG's Debug 30/09/2019 13:46:54
        return false;
      },
      closeDialogSubject: new Subject<void>()
  };

  private notesDialogOptions = {
      title: 'Notes',
      childComponent: NotesComponent,
			data: {
     		completionSubject: this.completionSubject,
     		variationId: '',
      },
      onClose: () => {
console.warn('Unexpected onClose'); // PIG's Debug 30/09/2019 13:46:54
        return false;
      },
      closeDialogSubject: new Subject<void>()
  };

/**
 * The constructor
 */
  public constructor(private modalDialogService: ModalDialogService,
                     private applicationRef: ApplicationRef) {
    this.rootViewContainerRef = (this.applicationRef.components[0].instance as AppComponent).rootViewContainerRef
  }

/**
 * Public methods
 */
  public remindPopup(variationIds: Array<string>, priceTriggerIds: Array<number>) {
    this.remindDialogOptions.data.variationIds = variationIds;
    this.remindDialogOptions.data.priceTriggerIds = priceTriggerIds;
    this.remindDialogOptions.title = (variationIds.length > 1) ? 'Snooze these ' + variationIds.length + ' alerts'
                                                              :  'Snooze this alert';
    this.modalDialogService.openDialog(this.rootViewContainerRef, this.remindDialogOptions);
    return this.completionSubject;
  }

  public referPopup(variationIds: Array<string>, priceTriggerIds: Array<number>) {
    this.referDialogOptions.data.variationIds = variationIds;
    this.referDialogOptions.data.priceTriggerIds = priceTriggerIds;
    this.referDialogOptions.title = (variationIds.length > 1) ? 'Refer these ' + variationIds.length + ' alerts'
                                                               : 'Refer this alert';
    this.modalDialogService.openDialog(this.rootViewContainerRef, this.referDialogOptions);
    return this.completionSubject;
  }

  public notesPopup(variationId: string) {
console.log('variationId',variationId); // PIG's Debug 05/11/2019 15:03:54
    this.notesDialogOptions.data.variationId = variationId;
    this.modalDialogService.openDialog(this.rootViewContainerRef, this.notesDialogOptions);
    return this.completionSubject;
  }

}
