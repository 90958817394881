/**
 * Import framework modules
 */
import { Component, ViewContainerRef } from '@angular/core';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from './general-services/browser-window.service';

/**
 * Declare the top level app component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'currentbody-xnd';

/** Note we inject the ViewContainerRef as a property so it can be referenced for attaching popups et al to the DOM root */
  public constructor(private browserWindowService: BrowserWindowService,
                     public rootViewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    /* this.browserWindowService. */ window.addEventListener("drop", event => {
      event && event.preventDefault();
    }, false);
  }

}
