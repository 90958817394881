/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject, forkJoin } from 'rxjs';

import { switchMap } from 'rxjs/operators';

/**
 * Import 3rd party modules
 */
import { ResizeEvent } from 'angular-resizable-element';
import { UIChart } from 'primeng/components/chart/chart';

/**
 * Import app specific elements
 */
import { BrowserWindowService } from '../general-services/browser-window.service';
import { ConfigurationService } from '../general-services/configuration.service';
import { AjaxData, AjaxDataService } from '../ajax-services/ajax-data.service';
import { DialogService } from '../general-services/dialog.service';
import { RegionCurrencies, priceChartOptions, lineColours } from "../data-definitions/global-constants";
import { capitalise } from "../general-functions/string-functions";

/**
 * Define the component
 */
@Component({
  selector: 'app-history-graph',
  templateUrl: './history-graph.component.html',
  styleUrls: ['./history-graph.component.css']
})
export class HistoryGraphComponent implements OnInit {

/**
 * External linkage
 */
  @Input() variationId: number;
  @Input() competitorId: number;
  @Input() competitors: Array<any> = [];
  @Input() region: string;
  @Input() style: string = "Price";
  @Input() height: number;
  @Input() width: number;
  @Input() set interval(value: string) {
    this._interval = value;
console.log('this.competitorId',this.competitorId); // PIG's Debug 27/02/2020 15:11:19
    this.fetchHistory(this.style,this.competitorId);
    if (this.style == "SalesPrice") {
      this.fetchHistory('Price',this.competitorId,1);
      // this.fetchHistory('Value',this.competitorId,2); // 2021-09-16 sidelined
    }
    if (this.style == "Adwords") {
      this.fetchHistory('AdShare',this.competitorId,1);
      this.fetchHistory('AdRevenue',this.competitorId,2);
    }
    if (this.style == "Textads") {
      this.fetchHistory('Textads2',this.competitorId,1);
      if (this.region == "sidelined-gb") {
        this.fetchHistory('TextadsUK',this.competitorId,2);
        this.fetchHistory('TextadsEU',this.competitorId,3);
        this.fetchHistory('TextadsXX',this.competitorId,4);
        this.fetchHistory('AdRevenue2',this.competitorId,5);
        this.fetchHistory('AdRevenue3',this.competitorId,6);
      } else {
        this.fetchHistory('AdRevenue2',this.competitorId,2);
        this.fetchHistory('AdRevenue3',this.competitorId,3);
      }
    }
    if (this.style == "TextadsUK") {
      if (this.region == "gb") {
        this.fetchHistory('TextadsEU',this.competitorId,1);
        this.fetchHistory('TextadsXX',this.competitorId,2);
      }
    }
    let index = 1;
    this._recordsSelected.forEach((record: any) => {
console.log('index',index); // PIG's Debug 27/02/2020 15:11:29
      if (record.id !== "1") { // don't add the Currentbody record which is already loaded as the base
        this.fetchHistory(this.style, record.id, index++);
      }
    })
  }
//  @Input() recordsSelected: Array<any> = [];
  @Input() set recordsSelected(values: Array<any>) {
    this._recordsSelected = values;
    if (this.historyData) { // a bit of chicken and egg, we need historyData set up conditionally in ngOninit, which won't be on first invocation of onChange
      this.updateSelection(values);
    }
  }


/**
 * Public properties
 */
  public historyData: any;
  public loading = true;
  public chartOptions: any;
  public dataReset: boolean = true;
  public chartReset = true;

/**
 * Private properties
 */
  private _interval = 'Q';
  private _recordsSelected: Array<any> = [];
  private windowRef: Window;
  private onDestroy$ = new Subject();
  private regionCurrencies = RegionCurrencies;

  private rangeBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject('Q');
  private currentRange$ = this.rangeBehaviorSubject.asObservable();

/**
 * External linkage
 */
  @ViewChild('historyChart', { static: false }) historyChart: UIChart;

/**
 * The constructor and init/destroy methods
 */
  public constructor(private browserWindowService: BrowserWindowService,
                     private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService) {
  }

  ngOnInit() {

    var lineColour;
    if (this.style === 'Stock') {
       lineColour = '#007700';
    } else if (this.style === 'Adwords') {
       lineColour = '#660000';
    } else if (this.style === 'Textads') {
       lineColour = '#0000AA';
    } else if (this.style === 'TextadsUK') {
       lineColour = '#FFAA7F';
    } else {
       lineColour = '#000000';
    }

    this.chartOptions = {
      title: {display: false,},
      legend: {
        display: (this.style === 'PriceComparison' || this.style === 'Adwords' || this.style === 'Textads' || this.style === 'TextadsUK'),
        labels: {
//usePointStyle: true
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 300,
        easing: 'linear'
      },
      elements: {
        point: {
          radius: 2,
          hitRadius: 20,
          hoverRadius: 5
        }
      },
      scales: {
        yAxes: [{
          id: 'lineAxis',
          type: 'linear',
          position: 'left',
          gridLines: {display: this.style !== 'SalesPrice'},
          ticks: {
            beginAtZero: (this.style !== 'Price' && this.style !== 'PriceComparison'),
            callback: value => {
              if (value % 1 === 0) {return value;} // only render integer values
            }
          }
        }],
        xAxes: [{
          display: (this.style !== 'Price'),
          gridLines: {display: false},
          scaleLabel: {
            display: true,
            labelString: (this._interval == 'M') ? 'Days' : 'Weeks'
          },
        }]
      }
    }

    let loc_label = this.style
    switch(this.style) {
      case 'PriceComparison':
        loc_label = 'Currentbody';
        break;
      case 'Adwords':
        loc_label = 'Clicks';
        break;
      case 'Textads':
        loc_label = 'SKU';
        break;
      case 'TextadsUK':
        loc_label = 'UK';
        break;
    default:
      // code block
    }

    this.historyData = {
      datasets: [{
          type: 'line',
//pointStyle: 'line',
          yAxisID: 'lineAxis',
          borderColor: lineColour,
          backgroundColor: lineColour,
//          label: (this.style === 'PriceComparison') ? 'Currentbody' : this.style.substr(0,6), // substr is a dirty hack to knock 'Price' off 'SalesPrice' on the combined sales/Price chart
          label: loc_label,
          fill: false,
          lineTension: 0.25,
          spanGaps: true,
        }]
    }

    if (this.style == 'SalesPrice') {
      this.chartOptions.scales.yAxes.push({
        id: 'barAxis',
        type: 'linear',
        position: 'right',
        ticks: {
          callback: value => {
            return (this.region) ? value.toLocaleString("en-GB",{style: "currency", currency: this.regionCurrencies[this.region]})
                                 : value;
          }
        }
      });
      this.historyData.datasets.push({
        type: 'bar',
        label: 'Price',
        yAxisID: 'barAxis',
        backgroundColor: '#72D5F5',
      });
/* 2021-09-16 sidelined
      this.historyData.datasets.push({
        type: 'bar',
        label: 'Value',
        yAxisID: 'barAxis',
        backgroundColor: '#AAAAAA',
      });
*/
    }

    if (this.style == 'Adwords') {
      this.chartOptions.scales.yAxes.push({
        id: 'barAxis',
        type: 'linear',
        position: 'right',
        ticks: {
/*
          callback: value => {
            return value;
          }
*/
          beginAtZero: true,
          callback: value => {
            return (this.region) ? value.toLocaleString("en-GB",{style: "currency", currency: this.regionCurrencies[this.region]})
                                 : value;
          }
        },
        gridLines: {
          display:false
        }
      });

      this.chartOptions.scales.yAxes.push({
        id: 'barAxis2',
        type: 'linear',
        position: 'right',
        ticks: {
/*
          callback: value => {
            return value;
          }
*/
          beginAtZero: true,
          max: 100,
          min: 0
        },
        display:false,
        gridLines: {
          display:false
        }
      });

      this.historyData.datasets.push({
        type: 'bar',
        label: 'Share',
        yAxisID: 'barAxis2',
        borderColor: '#BBBBBB',
        backgroundColor: '#BBBBBB',
      });

      this.historyData.datasets.push({
        type: 'bar',
        label: 'Revenue',
        yAxisID: 'barAxis',
        borderColor: '#00CCCC',
        backgroundColor: '#00CCCC',
      });
    }

    if (this.style == 'Textads') {
      this.chartOptions.scales.yAxes.push({
        id: 'barAxis',
        type: 'linear',
        position: 'right',
        ticks: {
/*
          callback: value => {
            return value;
          }
*/
          beginAtZero: true,
          callback: value => {
            return (this.region) ? value.toLocaleString("en-GB",{style: "currency", currency: this.regionCurrencies[this.region]})
                                 : value;
          }
        },
        gridLines: {
          display:false
        }
      });

/*
      this.chartOptions.scales.yAxes.push({
        id: 'Sessions2',
        type: 'linear',
        position: 'left',
        ticks: {
/ *
          callback: value => {
            return value;
          }
* /
        },
        gridLines: {
          display:false
        }
      });
*/

      this.historyData.datasets.push({
        type: 'line',
        fill: false,
        spanGaps: true,
        label: 'Product',
        yAxisID: 'lineAxis',
        //backgroundColor: '#DD00DD',
        borderColor: '#CCCCFF',
        backgroundColor: '#CCCCFF',
      });

      if (this.region == "gb-sidelined") {
        this.historyData.datasets.push({
          type: 'line',
          fill: false,
          spanGaps: true,
          label: 'UK',
          yAxisID: 'lineAxis',
          //backgroundColor: '#DD00DD',
          borderColor: '#FFAA7F',
          backgroundColor: '#FFAA7F',
        });

        this.historyData.datasets.push({
          type: 'line',
          fill: false,
          spanGaps: true,
          label: 'EU',
          yAxisID: 'lineAxis',
          //backgroundColor: '#DD00DD',
          borderColor: '#AAFFAA',
          backgroundColor: '#AAFFAA',
        });

        this.historyData.datasets.push({
          type: 'line',
          fill: false,
          spanGaps: true,
          label: 'ROW',
          yAxisID: 'lineAxis',
          //backgroundColor: '#DD00DD',
          borderColor: '#FFAAFF',
          backgroundColor: '#FFAAFF',
        });
      }

      this.historyData.datasets.push({
        type: 'bar',
        label: 'Revenue',
        yAxisID: 'barAxis',
        backgroundColor: '#00CCCC',
      });

      this.historyData.datasets.push({
        type: 'bar',
        label: 'Product',
        yAxisID: 'barAxis',
        backgroundColor: '#88EEEE',
      });

    }

    if (this.style == 'TextadsUK') {
/*
      this.chartOptions.scales.yAxes.push({
        id: 'barAxis',
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true,
          callback: value => {
            return (this.region) ? value.toLocaleString("en-GB",{style: "currency", currency: this.regionCurrencies[this.region]})
                                 : value;
          }
        },
        gridLines: {
          display:false
        }
      });
*/

      if (this.region == "gb") {
        this.historyData.datasets.push({
          type: 'line',
          fill: false,
          spanGaps: true,
          label: 'EU',
          yAxisID: 'lineAxis',
          //backgroundColor: '#DD00DD',
          borderColor: '#AAFFAA',
          backgroundColor: '#AAFFAA',
        });

        this.historyData.datasets.push({
          type: 'line',
          fill: false,
          spanGaps: true,
          label: 'ROW',
          yAxisID: 'lineAxis',
          //backgroundColor: '#DD00DD',
          borderColor: '#FFAAFF',
          backgroundColor: '#FFAAFF',
        });
      }

    }

    if (this.style == 'PriceComparison') {
      this.updateSelection(this._recordsSelected);
    }

  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */

/**
 * Public methods called from the template
 */
  private fetchHistory(style: string, competitorId = null, slot: number = 0) {
    const action = 'Variation' + capitalise(style);
    const formData: FormData = new FormData();
    formData.append('id',this.variationId.toString());
    if (this.region) { formData.append('region',this.region); }
    formData.append('cmp_id',competitorId.toString());
    formData.append('interval',this._interval);
    this.ajaxDataService.postAjaxDetails(action,formData)
      .subscribe(
        (ajaxData: AjaxData) => {
console.log("fetchHistory",competitorId,style,ajaxData);
          this.loadHistory(ajaxData, style, slot); // sales chart has price overlayed
        },
        error => {
          this.handleError(error);
        }
      );
  }

/**
 * Private methods (helper functions)
 */
  private loadHistory(ajaxData: AjaxData, style: string, slot: number) {
    const dataPoints = [];
    ajaxData.dataset.forEach((ajaxRow: any) => {
      switch(style) {
        case 'PriceComparison':
        case 'Price':
        case 'Value':
          dataPoints.push(ajaxRow.prh_price);
          break;
        case 'SalesPrice':
        case 'Sales':
          dataPoints.push(ajaxRow.slh_quantity);
          break;
        case 'Stock':
          dataPoints.push(ajaxRow.sth_available_stock);
          break;
        case 'Adwords':
          dataPoints.push(ajaxRow.adh_clicks);
          break;
        case 'AdShare':
          dataPoints.push(ajaxRow.adh_click_share / 100); // decimal percentage returned as 'x 100' integer
          break;
        case 'AdRevenue':
          dataPoints.push(ajaxRow.adh_revenue / 100); // decimal value returned as 'x 100' integer
          break;
        case 'Textads':
          dataPoints.push(ajaxRow.tah_sessions);
          break;
        case 'Textads2':
          dataPoints.push(ajaxRow.tah_prd_sessions);
          break;
        case 'TextadsUK':
          dataPoints.push(ajaxRow.tah_prd_sessions);
          break;
        case 'TextadsEU':
          dataPoints.push(ajaxRow.tah_prd_sessions);
          break;
        case 'TextadsXX':
          dataPoints.push(ajaxRow.tah_prd_sessions);
          break;
        case 'AdRevenue2':
          dataPoints.push(ajaxRow.tah_revenue / 100); // decimal value returned as 'x 100' integer
          break;
        case 'AdRevenue3':
          dataPoints.push(ajaxRow.tah_prd_revenue / 100); // decimal value returned as 'x 100' integer
          break;
      }
    });
console.log('loadHistory slot',slot,this.historyData.datasets); // PIG's Debug 28/10/2019 14:12:07
    this.historyData.datasets[slot].data = dataPoints;
    if (style == 'SalesPrice' && slot == 1) {
      this.setRoundedAxes(this.chartOptions.scales.yAxes[slot].ticks,dataPoints);
    }
    if (style == 'Adwords' && slot == 1) {
      this.setRoundedAxes(this.chartOptions.scales.yAxes[slot].ticks,dataPoints);
    }
    this.renderHistory(this.historyData, this._interval, slot)
  }

  private renderHistory(data: any, chartMode: string, index: number = 0) {
    if (!data.datasets[index]) return; // TODO rationalise and tidy this bail out
    const labels = [];
    data.datasets[index].data.forEach((entry,index) => {
      if (chartMode !== 'Y' || index % 4 === 0) {
        labels.push(index);
      } else {
        labels.push('');
      }
    });
    data.labels = labels.reverse();
    this.chartOptions.scales.xAxes[0].scaleLabel.labelString = (this._interval == 'M') ? 'Days' : 'Weeks';

    this.chartReset = false;
    setTimeout(() => {
      this.chartReset = true; // refresh workaround for https://github.com/primefaces/primeng/issues/3585
    },100);
  }

  private updateSelection(values: any) {
console.log('this.historyData.datasets',this.historyData.datasets); // PIG's Debug 06/02/2020 17:17:03
    this.historyData.datasets = this.historyData.datasets.slice(0,1);
//    this.historyData.datasets = [];
console.log('this.historyData.datasets',this.historyData.datasets); // PIG's Debug 06/02/2020 17:17:03
    let index = 1;
    values.forEach((record: any) => {
      if (record.id !== "1") { // don't add the Currentbody record which is already loaded as the base
        this.historyData.datasets.push({
          competitorId: record.id,
          type: 'line',
          yAxisID: 'lineAxis',
          borderColor: lineColours[index],
          backgroundColor: lineColours[index],
          label: this.competitors[record.id],
          fill: false,
          lineTension: 0.25,
          spanGaps: true,
        });
        this.fetchHistory(this.style,record.id,index++);
      }
    })
console.log('this.historyData.datasets',this.historyData.datasets); // PIG's Debug 06/02/2020 17:17:03
  }

  private setRoundedAxes(ticks,dataPoints) {
    const absoluteMin = Math.min(...dataPoints);
    const absoluteMax = Math.max(...dataPoints);
    const roundedMin = Math.floor(absoluteMin / 5) * 5;
    const roundedMax = Math.ceil(absoluteMax / 5) * 5;
    const stepSize = Math.ceil(Math.round((roundedMax - roundedMin) / 10) / 5) * 5; // divide into 10 steps and round interval up to the nearest 5
    ticks.min = Math.max(roundedMin - stepSize,0); // give a kick to the minimum so lowest level bars have some body
    ticks.max = roundedMax;
    ticks.stepSize = stepSize;
  }

/**
 * Report Errors
 */
  private handleError(error) {
console.error('handleError', error);
    return this.dialogService.reportError(error);
  }


}
