/**
 * Import framework modules
 */
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd, RoutesRecognized, ParamMap } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, pairwise } from 'rxjs/operators';

/**
 * Import app specific elements
 */
// import { GlobalSettings } from '../data-definitions/global-settings';
import { AjaxDataService, AjaxData } from '../ajax-services/ajax-data.service';
import { DialogService }             from '../general-services/dialog.service';
import { ConfigurationService } from '../general-services/configuration.service';

/**
 * Add a generic debug logging function extension to the Observable 'class'
 */
const debug = true;

/**
 * Define the component
 */
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

/**
 * Public properties
 */

/**
 * Private properties
 */
	private localUser: object;
  private onDestroy$ = new Subject();

/**
 * The constructor and init methods
 */
  public constructor(private configurationService: ConfigurationService,
                     private ajaxDataService: AjaxDataService,
                     private dialogService: DialogService,
                     private router: Router) {
		this.localUser = JSON.parse(localStorage.getItem('localUser'));
  }

  public ngOnInit() {
/*
    this.ajaxDataService.getAjaxDetails('Setup' + 'Details')
      .subscribe(
        (ajaxData: Array<any>) => {
          const dataset = ajaxData['dataset'][0];
          this.configurationService.development = dataset['stp_development'];
        },
        error => {
          this.handleError(error);
        }
      );
*/
  }

  public ngOnDestroy() {
    this.onDestroy$.next(); // terminate subscriptions waiting on this event
  }

/**
 * Event handlers
 */

/**
 * Report Errors
 */
  private handleError(error) {
console.error("handleError",error);
    return this.dialogService.reportError(error);
  }

}
