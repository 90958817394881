/*
  All material contained within is the intellectual property of Xanda Technology Ltd.
  and as such may not be reproduced in any way shape or form without the prior consent
  of Xanda Technology Ltd.
*/

/**
 * Import framework modules
 */
import { throwError, Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

/**
 * Import app specific elements
 */

/**
 * Define the component
 */
@Injectable({
  providedIn: 'root'
})
export class DataExportService {

/**
 * Public properties
 */

/**
 * Private properties
 */

/**
 * The constructor and init methods
 */
  public constructor() {
  }

/**
 * Public methods
 */
  downloadFile(data, filename = 'data') {
    var csvData;
    if (filename == 'weightings-download') {
      csvData = this.ConvertToCSV(data, [
          'name','code','region','manual_override','brand_importance','new_product','recent_product','competitor_increases','competitor_decreases','competitor_undercuts','monthly_revenue','monthly_revenue_threshold','traffic_level','traffic_level_threshold','out_of_stock','restocked','stock_exceeds','stock_exceeds_threshold','stock_fails','stock_fails_threshold','advert_position'
      ]);
    } else if (filename == 'brands-download') {
      csvData = this.ConvertToCSV(data, [
          'supplier','region','brand_importance'
      ]);
    } else {
      csvData = this.ConvertToCSV(data, [
          'name', 'code', 'stock', 'week1', 'week2', 'week3', 'week4', 'days28', 'days90'
      ]);
    }
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], {
        type: 'text/csv;charset=utf-8;'
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf(
        'Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1;
    //if Safari open in new window to save file with random filename.
    if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array =
        typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'index, ';
    for (let index in headerList) {
      row += headerList[index] + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
          let head = headerList[index];
          line += ', ' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

/**
 * Private methods
 */
  private handleError(error: HttpErrorResponse) {
console.error("AjaxDataService handleError",error);
	  return throwError(error);
  }

}
